import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import { fetchResult, getClubUser } from "../app/resultSlice";
import { transformSummaryData, cntAchievements } from "../helper/profileHelper";

export const useClubUserSummary = () => {
  const dispatch = useDispatch();
  const resultState = useSelector((state) => state.result);
  const { result, clubUser, isLoading } = resultState;

  const { username: clubUsername } = useParams();

  useEffect(() => {
    dispatch(fetchResult(clubUsername));
    dispatch(getClubUser(clubUsername));
  }, [clubUsername, dispatch]);

  const summaryData = useMemo(() => transformSummaryData(result), [result]);
  const cntAchievementsNo = useMemo(() => cntAchievements(result), [result]);

  const handleBack = () => {
    window.history.back();
  };

  return {
    isLoading,
    clubUser,
    summaryData,
    cntAchievementsNo,
    handleBack,
  };
};
