// useAuth.js
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { unwrapResult } from "@reduxjs/toolkit";

import {
  handleLogin,
  handleAvatarUpload,
  handleLogout,
  handleRegister,
} from "../app/authSlice";

export const useAuth = () => {
  const navigate = useNavigate();
  const auth = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const login = async (authData) => {
    try {
      const resultAction = await dispatch(handleLogin(authData));
      unwrapResult(resultAction);
      navigate("/");
    } catch (err) {
      console.log("err-->>", err);
    }
  };

  const register = async (authData) => {
    try {
      await dispatch(handleRegister(authData));
      navigate("/login");
    } catch (err) {
      console.log("err-->>", err);
    }
  };

  const handleAvatar = async (e) => {
    try {
      const file = e.target.files[0];
      const resultAction = await dispatch(handleAvatarUpload(file));
      return unwrapResult(resultAction)?.url;
    } catch (err) {
      console.log("err-->>", err);
    }
  };

  const logout = async () => {
    try {
      await dispatch(handleLogout(auth));
      navigate("/login");
    } catch (err) {
      console.log("err-->>", err);
    }
  };

  return { login, logout, register, handleAvatar, auth };
};
