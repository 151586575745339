import React, { useCallback, useState, useEffect, useMemo, useRef } from "react";
import { DateObject, Calendar } from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import EmailNotify from "../../helper/emailNotify";
import CustomModal from "../CustomModal";
import PriceModal from "../PriceModal";
import images from "../../helper/images";
import { transformSummaryData } from "../../helper/profileHelper";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PyramidCard = ({
  player,
  available,
  onlineShow,
  connectedUsers,
  isHighlighted,
  sendQuickFight,
  sendScheduledFight,
  imgSize,
}) => {
  const { t } = useTranslation();
  const userState = useSelector((state) => state.auth);
  const [isOpenSchedule, setIsOpenSchedule] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isPriceModal, setIsPriceModal] = useState(false);
  const [isSchedulePriceModal, setIsSchedulePriceModal] = useState(false);
  const [value, setValue] = useState(new DateObject());
  const [user, setUser] = useState({});
  const loggedInUserRef = useRef(null);

  const connected = useMemo(
    () =>
      connectedUsers.find(
        (val) =>
          val?.username?.toLowerCase() === player?.username?.toLowerCase()
      )?.status === "online" ||
      connectedUsers.find(
        (val) =>
          val?.username?.toLowerCase() === player?.username?.toLowerCase()
      )?.status === "occupied",
    [connectedUsers, player]
  );

  const occupied = useMemo(
    () =>
      connectedUsers.find(
        (val) =>
          val?.username?.toLowerCase() === player?.username?.toLowerCase()
      )?.status === "occupied",
    [connectedUsers, player]
  );

  useEffect(() => {
    const authUser = userState?.user;
    if (authUser) {
      setUser(authUser);
    }

    loggedInUserRef.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, [userState, player]);

  const closeModalSchedule = () => setIsOpenSchedule(false);
  const openModalSchedule = () => setIsOpenSchedule(true);

  const handleClickProfile = useCallback((payload) => {
    window.location.href = `/profile/${payload}`;
  }, []);

  const isDateValid = (selectedDate) => {
    if (selectedDate) {
      const currentDate = moment().startOf("day");
      const selectedMoment = moment(selectedDate, "YYYY-MM-DD").startOf("day");
      const nextThreeDays = moment().add(3, "days").startOf("day");

      return selectedMoment.isBetween(currentDate, nextThreeDays, null, "[]");
    }
    return false;
  };

  const onSendSchedule = () => {
    const selectedDate = new Date(
      value.year,
      value.month.index,
      value.day,
      value.hour,
      value.minute
    );
    if (!isDateValid(selectedDate))
      toast(
        "Reservierte Herausforderungen werden innerhalb von 72 Stunden verfügbar sein."
      );
    else {
      setIsSchedulePriceModal(true);

      closeModalSchedule();
    }
  };

  const sendScheduleEmail = () => {
    EmailNotify.sendNotificationEmail(
      user?.username,
      user?.email,
      player?.username,
      player?.email,
      `${user?.username?.toLowerCase()} sent you a scheduled challenge. Challenge date: ${new Date(
        value.year,
        value.month.index,
        value.day,
        value.hour,
        value.minute
      )}.`,
      "Dart Challenge"
    );
  };

  const sendScheduleNotification = (receiver, challenger, priceOption) => {
    const selectedDate = new Date(
      value.year,
      value.month.index,
      value.day,
      value.hour,
      value.minute
    );

    sendScheduledFight(
      selectedDate,
      challenger,
      user?.email,
      receiver,
      player?.email,
      priceOption
    );
  };

  const sendQuick = () => {
    EmailNotify.sendNotificationEmail(
      user?.username,
      user?.email,
      player?.username,
      player?.email,
      `${user?.username?.toLowerCase()} sent you a challenge. Please login https://lidarts.org and accept the challenge. Your username must be same with username of lidarts.org`,
      "Dart Challenge"
    );
  };

  const onClickModal = () => setIsOpenModal(true);
  const onCloseModal = () => setIsOpenModal(false);
  const onClosePriceModal = () => setIsPriceModal(false);
  const onCloseSchedulePriceModal = () => setIsSchedulePriceModal(false);
  return (
    <div>
      <div
        id={player._id}
        ref={
          player?.username?.toLowerCase() === user?.username?.toLowerCase()
            ? loggedInUserRef
            : null
        }
        className={classNames(
          "group relative flex m-2",
          isHighlighted ? "border border-2 border-green-500 bg-green-100" : "",
          player?.username?.toLowerCase() === user?.username?.toLowerCase()
            ? "border border-2 border-green-500 bg-green-100"
            : "",
          !player?.active && "opacity-50 pointer-events-none",
          onlineShow && !connected && "hidden",
          player?.vAvatar?.ranks > 0 ? "" : "p-2 shadow"
        )}
      >
        {connected ? (
          occupied ? (
            <span className="absolute z-10 top-1 left-1 flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-yellow-600 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-yellow-700"></span>
            </span>
          ) : (
            <span className="absolute z-10 top-1 left-1 flex h-2 w-2">
              <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-600 opacity-75"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-green-700"></span>
            </span>
          )
        ) : (
          <span className="absolute z-10 top-1 left-1 flex h-2 w-2">
            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-gray-600 opacity-75"></span>
            <span className="relative inline-flex rounded-full h-2 w-2 bg-gray-700"></span>
          </span>
        )}
        <div className="cursor-pointer" onClick={onClickModal}>
          <div className="relative">
            {player.avatar ? (
              <img
                className={`mx-auto flex-shrink-0 rounded-full z-30 ${
                  player?.vAvatar?.ranks > 0 ? "absolute" : ""
                }`}
                src={player.avatar}
                style={{
                  width: `${
                    player?.vAvatar?.ranks > 0 ? imgSize * 3 : imgSize * 5
                  }px`,
                  height: `${
                    player?.vAvatar?.ranks > 0 ? imgSize * 3 : imgSize * 5
                  }px`,
                  top: "28%",
                  left: "50%",
                  transform: `translateX(-${
                    player?.vAvatar?.ranks > 0 ? 50 : 0
                  }%)`,
                }}
                alt="user avatar"
              />
            ) : (
              <div
                className={`mx-auto flex items-center justify-center flex-shrink-0 bg-green-200 rounded-full text-xl font-bold z-30 ${
                  player?.vAvatar?.ranks > 0 ? "absolute" : ""
                }`}
                style={{
                  width: `${
                    player?.vAvatar?.ranks > 0 ? imgSize * 3 : imgSize * 5
                  }px`,
                  height: `${
                    player?.vAvatar?.ranks > 0 ? imgSize * 3 : imgSize * 5
                  }px`,
                  top: "28%",
                  left: "50%",
                  transform: `translateX(-${
                    player?.vAvatar?.ranks > 0 ? 50 : 0
                  }%)`,
                }}
              >
                {player?.username.toLocaleUpperCase().charAt(0)}
              </div>
            )}
            {player?.vAvatar?.ranks > 0 ? (
              <img
                style={{
                  width: `${imgSize * 7}px`,
                  height: `${imgSize * 7}px`,
                }}
                src={images.VAVATAR[`RANKAVATAR${player?.vAvatar?.ranks}`]}
                alt="top-place"
              />
            ) : null}
          </div>
        </div>
      </div>

      <CustomModal isOpen={isOpenModal} onClose={onCloseModal}>
        <div className="relative w-full bg-white dark:bg-gray-800">
          <div className="flex flex-col items-center py-4">
            <div className="relative">
              {player.avatar ? (
                <img
                  className={`w-16 h-16 rounded-full shadow-lg ${
                    player?.vAvatar?.ranks > 0
                      ? "absolute left-1/2 top-[28%] -translate-x-1/2"
                      : ""
                  }`}
                  src={player.avatar}
                  alt="user avatar"
                  onClick={() => handleClickProfile(player?.username)}
                />
              ) : (
                <div
                  className={`w-16 h-16 rounded-full bg-green-200 shadow-lg flex items-center justify-center text-5xl ${
                    player?.vAvatar?.ranks > 0
                      ? "absolute left-1/2 top-[28%] -translate-x-1/2"
                      : ""
                  }`}
                  onClick={() => handleClickProfile(player?.username)}
                >
                  {player?.username?.toUpperCase().charAt(0)}
                </div>
              )}
              {player?.vAvatar?.ranks > 0 ? (
                <img
                  className="w-40 h-40"
                  src={images.VAVATAR[`RANKAVATAR${player?.vAvatar?.ranks}`]}
                  alt="top-place"
                />
              ) : null}
            </div>

            <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
              {player?.username?.toUpperCase()}
            </h5>
            <div className="mt-2">
              {player?.createdAt && (
                <p className="text-sm text-center text-gray-500 dark:text-gray-400">
                  {t("register")}: {new Date(player?.createdAt).toDateString()}
                </p>
              )}
              <div className="flex gap-2 justify-center">
                <p className="text-sm text-gray-500 dart:text-gray-400">
                  {t("fight")}:{" "}
                  {player?.friendlyChallenger?.lifetime +
                    player?.readyForIt?.lifetime}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t("doubles")}:{" "}
                  {transformSummaryData(player)?.overall?.doubles}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t("average")}:{" "}
                  {transformSummaryData(player)?.overall?.matchAvg}
                </p>
                <p className="text-sm text-gray-500 dark:text-gray-400">
                  {t("first9Avg")}:{" "}
                  {transformSummaryData(player)?.overall?.first9Avg}
                </p>
              </div>
            </div>
            <div className="flex mt-4 md:mt-6 gap-2">
              <button
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:opacity-50"
                disabled={
                  player?.username?.toLowerCase() ===
                    user?.username?.toLowerCase() ||
                  !available ||
                  !connected ||
                  occupied
                }
                onClick={() => {
                  setIsPriceModal(true);
                  setIsOpenModal(false);
                }}
              >
                {t("quickFight")}
              </button>
              <button
                className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-green-600 rounded-lg hover:bg-green-500 focus:ring-4 focus:outline-none focus:ring-green-300 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800 disabled:opacity-50"
                disabled={
                  player?.username?.toLowerCase() ===
                    user?.username?.toLowerCase() || !available
                }
                onClick={openModalSchedule}
              >
                {t("scheduleFight")}
              </button>
            </div>
          </div>
          {player?.level === 6 ? (
            <div className="absolute top-0 right-0">
              <img className="w-8 h-8" src={images.GOLDCROWN} alt="badge" />
            </div>
          ) : player?.level === 5 ? (
            <div className="absolute top-0 right-0">
              <img className="w-8 h-8" src={images.SNDBADGE} alt="badge" />
            </div>
          ) : (
            player?.level === 0 && (
              <div className="absolute top-0 right-0">
                <img className="w-8 h-8" src={images.BEGINNER} alt="badge" />
              </div>
            )
          )}
        </div>
      </CustomModal>

      <CustomModal
        title="Scheduled Fight"
        isOpen={isOpenSchedule}
        onClose={closeModalSchedule}
      >
        <div className="mt-2">
          <p className="text-sm bg-white text-gray-500 dark:bg-gray-800 dark:text-gray-200">
            You can send the scheduled challenge to your opponent.
          </p>
        </div>
        <div className="mt-2 flex justify-center">
          <Calendar
            value={value}
            onChange={setValue}
            plugins={[<TimePicker />]}
          />
        </div>

        <div className="mt-4 flex justify-end gap-2">
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            onClick={onSendSchedule}
          >
            Send
          </button>
          <button
            type="button"
            className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-green-500 focus-visible:ring-offset-2"
            onClick={closeModalSchedule}
          >
            Close
          </button>
        </div>
      </CustomModal>
      <PriceModal
        isOpenModal={isPriceModal}
        onCloseModal={onClosePriceModal}
        sendChallenge={sendQuick}
        sendQuickNotification={sendQuickFight}
        challenger={user?.username}
        receiver={player?.username}
      />
      <PriceModal
        isOpenModal={isSchedulePriceModal}
        onCloseModal={onCloseSchedulePriceModal}
        sendChallenge={sendScheduleEmail}
        sendQuickNotification={sendScheduleNotification}
        challenger={user?.username}
        receiver={player?.username}
      />
    </div>
  );
};

export default React.memo(PyramidCard);
