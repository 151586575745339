import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import { updateGlobalCoins, buyPrice, updatePrice } from "../app/priceSlice";

export const usePrice = () => {
  const dispatch = useDispatch();
  const { customPrice, defaultPrice, globalCoins, loading } = useSelector(
    (state) => state.price
  );
  const { user } = useSelector((state) => state.auth);
  //   "price--->>>",
  //   user,
  //   customPrice,
  //   defaultPrice,
  //   globalCoins,
  //   loading,
  //   error
  // );

  const buyCustomPrice = async (price) => {
    try {
      const resultAction = await dispatch(
        buyPrice({ userId: user._id, price })
      );
      if (buyPrice.fulfilled.match(resultAction)) {
        toast.success(`Successfully purchased ${price} coins`);
        if (Number(price) === 50) {
          updateGlobalCoin(5);
        }
      } else {
        toast.error("Failed to update price.");
      }
    } catch (err) {
      console.error("An error occurred", err);
    }
  };

  const updateCustomPrice = async (userId, type, balance) => {
    try {
      await dispatch(updatePrice({ userId, balance, type }));
    } catch (err) {
      console.error("An error occurred", err);
    }
  };

  const updateGlobalCoin = async (price) => {
    try {
      const resultAction = await dispatch(updateGlobalCoins(price));
      // if (updateGlobalCoins.fulfilled.match(resultAction)) {
      //   toast.success("Successfully added global coins.");
      // } else {
      //   toast.error("Failed to add global coins.");
      // }
    } catch (err) {
      console.error("An error occurred", err);
    }
  };

  return {
    customPrice,
    defaultPrice,
    globalCoins,
    loading,
    updateCustomPrice,
    buyCustomPrice,
    updateGlobalCoin,
  };
};
