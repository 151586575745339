import { Disclosure } from "@headlessui/react";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const MobileMenuButton = ({
  isOpenSidebar = false,
  onOpenSidebar,
  onCloseSidebar,
}) => {
  const { t } = useTranslation();
  return (
    <div>
      {isOpenSidebar ? (
        ""
      ) : (
        <Disclosure.Button
          className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          onClick={onOpenSidebar}
        >
          <span className="absolute -inset-0.5" />
          <span className="sr-only">{t("openMainMenu")}</span>
          <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
        </Disclosure.Button>
      )}
    </div>
  );
};

export default MobileMenuButton;
