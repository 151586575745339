import { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { useTranslation } from "react-i18next";

import constant from "../../helper/constant";
import { classNames } from "../../helper/helper";
import TranslationIcon from "../SVG/TranslationIcon";

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <Menu as="div" className="relative ml-3">
      <div>
        <Menu.Button className="relative flex">
          <span className="absolute -inset-1.5" />
          <span className="sr-only">{t("selectLanguage")}</span>
          {/* <LanguageIcon className="h-6 w-6" aria-hidden="true" /> */}
          <TranslationIcon />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute -right-8 z-10 mt-2 w-24 origin-top-right rounded-md bg-white dark:bg-gray-800 py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
          {constant.languageList.map((item, index) => (
            <Menu.Item key={index}>
              {({ active }) => (
                <button
                  className={classNames(
                    active ? "bg-gray-100 dark:bg-gray-900" : "",
                    "block w-full px-4 py-2 text-sm text-gray-700 dark:bg-gray-800 dark:text-gray-200"
                  )}
                  onClick={() => changeLanguage(item.value)}
                >
                  {item.label}
                </button>
              )}
            </Menu.Item>
          ))}
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export default LanguageSelect;
