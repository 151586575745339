import { useTranslation } from "react-i18next";

import { CustomButton } from "../../components/CustomButton";
import SummaryStaticCard from "../../components/Profile/SummaryStaticCard";
import CustomTextAreaComponent from "../../components/CustomTextAreaComponent";
import Loading from "../../components/Loading";
import AchievementImages from "../../helper/images";
import { useSummary } from "../../hooks/useSummary";
import { setTotalAchievements } from "../../helper/profileHelper";

const ProfileSummay = () => {
  const {
    isLoading,
    user,
    profile,
    summaryData,
    formData,
    cntAchievementsNo,
    isEditable,
    lastLogin,
    setIsEditable,
    saveProfile,
    onChange,
  } = useSummary();
  const { t } = useTranslation();

  return (
    <div className="text-black dark:text-white">
      {cntAchievementsNo >= 5 && (
        <div className="flex justify-center">
          <div className="w-1/2">
            <img
              src={
                AchievementImages.HUNTER[
                  `hunter_${setTotalAchievements(cntAchievementsNo)}`
                ]
              }
              alt="total achievement"
            />
          </div>
        </div>
      )}
      <div className="w-full flex justify-end items-center mb-8">
        {isEditable ? (
          <CustomButton className="py-2 px-4 rounded-md" onClick={saveProfile}>
            {t("save")}
          </CustomButton>
        ) : (
          <CustomButton
            className="py-2 px-4 rounded-md"
            onClick={() => setIsEditable(true)}
          >
            {t("editProfile")}
          </CustomButton>
        )}
      </div>
      <div className="mb-8">
        {isEditable ? (
          <CustomTextAreaComponent
            name="profile"
            placeholder="Please enter your introduction here..."
            value={formData?.profile}
            handleTextArea={onChange}
          />
        ) : (
          <h5 className="w-full min-h-60 p-4 text-xl text-left dark:bg-black border border-t-4 border-t-green-600 dark:border-yellow-600 dark:border-t-green-600 rounded-md cursor-pointer ">
            {profile
              ? profile
              : "Introduction does not exist."}
          </h5>
        )}
      </div>
      <div className="text-left text-md md:text-xl lg:text-2xl dark:bg-black mb-8 p-4 border border-t-4 border-t-green-600 dark:border-yellow-600 dark:border-t-green-600 rounded-md">
        {t("lastOnline")}:{" "}
        <span className="font-bold">
          {new Date(lastLogin).toLocaleString()}
        </span>
      </div>
      {isLoading ? <Loading /> : <SummaryStaticCard result={summaryData} />}
    </div>
  );
};

export default ProfileSummay;
