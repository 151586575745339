// useSorting.js
import { useState, useCallback } from "react";

export const useSorting = (initialSortObj) => {
  const [sortObj, setSortObj] = useState(initialSortObj);

  const handleSort = useCallback((columns, sortDirection) => {
    setSortObj((prevState) => ({ ...prevState, sortDirection }));
  }, []);

  return { sortObj, handleSort };
};
