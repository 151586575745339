import { getRandomColor } from "../helper/helper";

const AvatarComponent = ({
  username,
  avatar,
  avatarSize = 24,
  isRoundedFull = true,
}) => {
  const bgColor = getRandomColor(username);
  return (
    <div>
      {avatar ? (
        <img
          className={`${
            isRoundedFull ? "rounded-full" : "rounded-md"
          } shadow-lg`}
          style={{
            width: avatarSize * 4,
            maxWidth: avatarSize * 4,
            height: avatarSize * 4,
          }}
          src={avatar}
          alt="user avatar"
        />
      ) : (
        <div
          style={{ backgroundColor: bgColor }}
          className={`w-${avatarSize} h-${avatarSize} ${
            isRoundedFull ? "rounded-full" : "rounded-md"
          } shadow-lg flex items-center justify-center text-5xl text-white`}
        >
          {username?.toUpperCase().charAt(0)}
        </div>
      )}
    </div>
  );
};

export default AvatarComponent;
