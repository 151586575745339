import { useTranslation } from "react-i18next";

import CustomModal from "../CustomModal";
import { getCurrentTimeZone } from "../../helper/helper";

const ScheduleModal = ({ isOpen, user, currentSchedule, onClose, onClick, onDecline }) => {
  const { t } = useTranslation();
  return (
    <CustomModal title={t("scheduledFight")} isOpen={isOpen} onClose={onClose}>
      <p className="bg-white dark:bg-gray-800 text-black dark:text-white">
        {t('challenge')} - {currentSchedule?.challenger} : {currentSchedule?.receiver}{" "}
      </p>
      <p className="bg-white dark:bg-gray-800 text-black dark:text-white">
        Time:
        {new Date(currentSchedule?.date).toLocaleString("en-US", {
          timeZone: getCurrentTimeZone(),
          year: "numeric",
          month: "long",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
        })}
      </p>
      <div className="mt-4 flex justify-end gap-2">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:bg-green-400"
          onClick={onClick}
          disabled={
            user.username?.toLowerCase() ===
            currentSchedule?.receiver?.toLowerCase()
          }
        >
          {t("create")}
        </button>
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2 disabled:opacity-50 disabled:bg-green-400"
          onClick={onDecline}
          disabled={
            user.username?.toLowerCase() ===
            currentSchedule?.challenger?.toLowerCase()
          }
        >
          {t("decline")}
        </button>
      </div>
    </CustomModal>
  );
};

export default ScheduleModal;
