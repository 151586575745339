import { motion } from "framer-motion";

import { useProfile } from "../../hooks/useProfile";
import Loading from "../../components/Loading";
import AchievementImages from "../../helper/images";
import AchievementItemComponent from "../../components/Profile/AchievementItem";
import HandleAchievement from "../../helper/achievements";
import AchievementGrandmaster from "../../components/Profile/AchievementGrandmaster";
import AchievementMaxMarks from "../../components/Profile/AchievementMaxMarks";
import constant from "../../helper/constant";

const ProfilePersonAchievements = () => {
  const { personalAchievementItems, highIndex, result, isLoading } =
    useProfile();
  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col w-full p-4 bg-white dark:bg-gray-800 text-black dark:text-white gap-4">
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </div>
      ) : (
        <div className="p-4 shadow-md rounded-md">
          <motion.ul
            variants={constant.Animationcontainer}
            initial="hidden"
            animate="visible"
            className="flex flex-col lg:flex-row sm:flex-wrap gap-4"
          >
            <AchievementGrandmaster
              result={result?.grandMaster}
              achievementIcons={AchievementImages.GRANDMASTERAVERAGE}
              handleActive={HandleAchievement.grandMaster}
            />
            <AchievementMaxMarks
              achievementIcon={AchievementImages.MAXIMUMMARKSMAN}
            />
            {personalAchievementItems?.map((item, index) => (
              <AchievementItemComponent
                key={index}
                result={item.result}
                title={item.title}
                achievement={item.achievement}
                type={item.type}
                max={item.max}
                iconSize="w-16 h-12"
                achievementIcons={item.achievementIcons}
                handleActive={item.handleActive}
              />
            ))}
          </motion.ul>

          <div className="flex flex-wrap justify-center mt-8 max-h-40 sm:max-h-full sm:overflow-y-none overflow-y-auto">
            {AchievementImages.FINISHINGACE.map((val, index) =>
              highIndex?.includes(index) ? (
                <div
                  className="sm:flex sm:items-center sm:w-20 w-[33.3%]"
                  key={index}
                >
                  <img
                    src={val}
                    className="sm:w-20 sm:h-20 w-full"
                    alt="achievement-icon"
                  />
                </div>
              ) : (
                <div
                  className="sm:flex sm:items-center sm:w-20 w-[33.3%]"
                  key={index}
                >
                  <img
                    src={val}
                    className="opacity-50 sm:w-20 sm:h-20 w-full"
                    alt="achievement-icon"
                  />
                </div>
              )
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfilePersonAchievements;
