import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../helper/http-client";

export const fetchResult = createAsyncThunk(
  "result/fetchResult",
  async (username, thunkAPI) => {
    try {
      const res = await http.post("/result/fetch", {
        username: username.trim()?.toLowerCase(),
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const fetchResultAll = createAsyncThunk(
  "result/fetchResultAll",
  async (thunkAPI) => {
    try {
      const res = await http.get("/result/fetch-all");
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const fetchProfile = createAsyncThunk(
  "result/fetchProfile",
  async (userId, thunkAPI) => {
    try {
      const res = await http.get(`/auth/get-profile/${userId}`);
      return res.data?.profile;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  "result/updateProfile",
  async ({ userId, profile }, thunkAPI) => {
    try {
      const res = await http.post(`/auth/update-profile/${userId}`, {
        profile,
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateResult = createAsyncThunk(
  "result/updateResult",
  async (result, thunkAPI) => {
    try {
      const res = await http.post("/result/post", result);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const getClubUser = createAsyncThunk(
  "result/getClubUser",
  async (username, thunkAPI) => {
    try {
      const res = await http.get(`/auth/get-user/${username}`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const initialState = {
  results: [],
  result: {},
  profile: "",
  clubUser: {},
  isLoading: false,
  error: null,
};

export const resultSlice = createSlice({
  name: "result",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchResult.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchResult.fulfilled, (state, action) => {
        state.result = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchResult.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(updateProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(updateProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchProfile.fulfilled, (state, action) => {
        state.profile = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchProfile.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(fetchResultAll.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchResultAll.fulfilled, (state, action) => {
        state.results = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchResultAll.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(getClubUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClubUser.fulfilled, (state, action) => {
        state.clubUser = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(getClubUser.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export default resultSlice.reducer;
