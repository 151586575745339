import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

import seasonSlice from "./seasonSlice";
import authSlice from "./authSlice";
import eventSlice from "./eventSlice";
import resultSlice from "./resultSlice";
import scheduleSlice from "./scheduleSlice";
import priceSlice from "./priceSlice";

const persistConfig = {
  key: "root",
  storage,
};

const persistedReducer = persistReducer(persistConfig, authSlice);

export const store = configureStore({
  reducer: {
    season: seasonSlice,
    auth: persistedReducer,
    event: eventSlice,
    result: resultSlice,
    schedule: scheduleSlice,
    price: priceSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
