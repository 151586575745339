import React, { useState, useEffect } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { ChevronDownIcon, ChevronRightIcon } from "@heroicons/react/24/outline";

import { useConstant } from "../hooks/useConstant";
import { classNames } from "../helper/helper";
import SidebarLinkGroup from "./SideLinkGroup";

const SideMenuComponent = () => {
  const { navigation } = useConstant();
  const location = useLocation();
  const { pathname } = location;

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded.toString());
    if (sidebarExpanded) {
      document.querySelector("body")?.classList.add("sidebar-expanded");
    } else {
      document.querySelector("body")?.classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <div>
      <ul className="flex flex-col gap-2 pl-4">
        {navigation.map((item, index) => {
          if (Object.keys(item).includes("children")) {
            return (
              <li key={index}>
                <SidebarLinkGroup
                  activeCondition={pathname.includes(item.current)}
                >
                  {(handleClick, open) => {
                    return (
                      <React.Fragment>
                        <NavLink
                          to="#"
                          className={`group relative flex items-center gap-6 px-6 py-4 font-medium text-xl dark:text-white font-medium hover:bg-gradient-to-r hover:from-[#2F0DF411] hover:to-gray-100 dark:hover:from-gray-900 dark:hover:to-gray-800 duration-300 ease-in-out hover:rounded-tl-md hover:rounded-bl-md hover:border-r-4 hover:border-gray-900 dark:hover:border-gray-900 ${
                            (pathname === "/" ||
                              pathname.includes(item.current)) &&
                            "bg-gradient-to-r from-[#2F0DF411] dark:from-gray-900 from-[60%] to-gray-100 dark:to-gray-800 rounded-tl-md rounded-bl-md border-r-4 border-gray-900 dark:border-gray-900 dark:text-white"
                          }`}
                          onClick={(e) => {
                            e.preventDefault();
                            sidebarExpanded
                              ? handleClick()
                              : setSidebarExpanded(true);
                          }}
                        >
                          <span>{item.icon}</span>
                          {item.name}
                          {open ? (
                            <ChevronDownIcon className="ml-auto w-4 h-4" />
                          ) : (
                            <ChevronRightIcon className="ml-auto w-4 h-4" />
                          )}
                        </NavLink>
                        <div
                          className={`translate transform overflow-hidden ${
                            !open && "hidden"
                          }`}
                        >
                          <ul className="mt-4 mb-6 flex flex-col gap-2.5 pl-6 pr-4">
                            {item?.children?.map((child, subIndex) => (
                              <li key={subIndex}>
                                <NavLink
                                  to={child.to}
                                  className={({ isActive }) =>
                                    "group relative flex gap-4 items-center pl-4 text-sm text-left dark:text-white font-medium opacity-60 hover:opacity-100 " +
                                    (isActive && "!opacity-100")
                                  }
                                >
                                  <span>{child.icon}</span>
                                  {child.name}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </div>
                      </React.Fragment>
                    );
                  }}
                </SidebarLinkGroup>
              </li>
            );
          } else
            return (
              <li key={index}>
                <NavLink
                  key={item.name}
                  to={item.to}
                  className={classNames(
                    pathname.includes(item.current)
                      ? "bg-gradient-to-r from-[#2F0DF411] dark:from-gray-900 from-[60%] to-gray-100 dark:to-gray-800 rounded-tl-md rounded-bl-md border-r-4 border-gray-900 dark:border-gray-900 dark:text-white"
                      : "",
                    "flex gap-6 items-center px-6 py-4 text-xl dark:text-white font-medium hover:bg-gradient-to-r hover:from-[#2F0DF411] hover:to-gray-100 dark:hover:from-gray-900 dark:hover:to-gray-800 duration-300 ease-in-out hover:rounded-tl-md hover:rounded-bl-md hover:border-r-4 hover:border-gray-900 dark:hover:border-gray-900"
                  )}
                  aria-current={
                    pathname.includes(item.current) ? "page" : undefined
                  }
                >
                  <span>{item.icon}</span>
                  {item.name}
                </NavLink>
              </li>
            );
        })}
      </ul>
    </div>
  );
};

export default SideMenuComponent;
