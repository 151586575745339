import { useTranslation } from "react-i18next";

const Tutorial = () => {
  const { t } = useTranslation();
  return (
    <div>
      <h2 className="bg-white dark:bg-gray-800 text-black dark:text-white font-bold text-3xl">{t('comingSoon')}</h2>
    </div>
  );
};

export default Tutorial;
