import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, EffectCoverflow } from "swiper/modules";

import images from "../../helper/images";

const HomeSlider = () => {
  const { HOMESLIDER1, HOMESLIDER2, HOMESLIDER3, HOMESLIDER4 } = images;

  const slides = [
    {
      image: HOMESLIDER1,
      text: "Dart Fight Club is an online platform where dart enthusiasts can challenge each other to friendly competitions. Whether you’re a seasoned pro or a beginner, our platform allows you to hone your skills, challenge friends, and keep track of your progress in the world of darts.",
      title: "Introduction to Dart Fight Club",
    },
    {
      image: HOMESLIDER2,
      text: 'Open the Dart Fight Club App: Navigate to the “Challenges” section. Choose a friend or let the app pick a random opponent. Set your preferred game type, number of rounds, and scoring method. Tap "Send" and wait for your opponent to accept.',
      title: "How to Send a Quick Challenge",
    },
    {
      image: HOMESLIDER3,
      text: "Click the “Scheduled Challenges” button. Pick a suitable time for both you and your opponent. Send the challenge to your chosen opponent and wait for their confirmation.",
      title: "How to Send & Play a Scheduled Challenge",
    },
    {
      image: HOMESLIDER4,
      text: "Once the match concludes, both players will see the final score. Both players must confirm the match result on their screens. The result is then saved to your profile, and rankings are updated automatically.",
      title: "How to Save the Fight Result",
    },
  ];

  return (
    <Swiper
      effect={"coverflow"}
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      slidesPerView={"auto"}
      coverflowEffect={{
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      }}
      loop={true}
      grabCursor={true}
      modules={[Pagination, Autoplay, EffectCoverflow]}
      className="mySwiper"
    >
      {slides.map((slide, index) => (
        <SwiperSlide key={index}>
          <div key={index} className="relative flex">
            <div className="w-full">
              <img
                className="w-full h-64"
                src={slide.image}
                alt="home-slider1"
              />
            </div>
            <div className="absolute top-0 w-full h-full bg-gradient-to-br from-black from-10% to-[#56565600] to-100% rounded-3xl"></div>
            <div className="absolute flex flex-col justify-center w-full top-0 text-white p-4">
              <p className="text-sm sm:text-2xl font-bold my-4 text-left">
                {slide.title}
              </p>
              <p className="text-left text-sm sm:text-md">{slide.text}</p>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default HomeSlider;
