import {
  HomeIcon,
  AdjustmentsVerticalIcon,
  InformationCircleIcon,
  IdentificationIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  ChartBarIcon,
  TrophyIcon,
  RocketLaunchIcon,
  UserCircleIcon,
  CircleStackIcon
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

export const useConstant = () => {
  const { t } = useTranslation();
  const infoMenus = [
    {
      name: t("rules"),
      to: "/infos/rules",
      current: "/rules",
      icon: <ExclamationCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("additive"),
      to: "/infos/additive",
      current: "/additive",
      icon: <InformationCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("tutorial"),
      to: "/infos/tutorial",
      current: "/tutorial",
      icon: <QuestionMarkCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
  ];

  const profileMenuItems = [
    {
      name: t("summary"),
      to: "/profile/summary",
      current: "summary",
      icon: <UserCircleIcon className="w-6 h-6" aria-hidden="true" />,
      position: "top-4",
      width: "group-hover:w-40",
    },
    {
      name: t("pyramidAchievements"),
      to: "/profile/pyramid",
      current: "/profile/pyramid",
      icon: <ChartBarIcon className="w-6 h-6" aria-hidden="true" />,
      position: "top-16",
      width: "group-hover:w-56",
    },
    {
      name: t("fightClubAchievements"),
      to: "/profile/participation",
      current: "/participation",
      icon: <RocketLaunchIcon className="w-6 h-6" aria-hidden="true" />,
      position: "top-28",
      width: "group-hover:w-60",
    },
    {
      name: t("personalAchievements"),
      to: "/profile/personal",
      current: "/personal",
      icon: <TrophyIcon className="w-6 h-6" aria-hidden="true" />,
      position: "top-40",
      width: "group-hover:w-56",
    },
    {
      name: t("myCalendar"),
      to: "/profile/calendar",
      current: "/calendar",
      icon: <CalendarDaysIcon className="w-6 h-6" aria-hidden="true" />,
      position: "top-52",
      width: "group-hover:w-40",
    },
  ];

  const eventFilterList = [
    { label: t("login"), value: "login", name: "login" },
    { label: t("logout"), value: "logout", name: "logout" },
    { label: t("register"), value: "register", name: "register" },
    { label: t("fight"), value: "match", name: "match" },
    { label: t("schedule"), value: "schedule", name: "schedule" },
    { label: t("quick"), value: "quick", name: "quick" },
    { label: t("quickToken"), value: "quick-token", name: "quick-token" },
    { label: t("scheduleToken"), value: "schedule-token", name: "schedule-token" },
    { label: t("decline"), value: "decline", name: "decline" },
    { label: t("cancel"), value: "cancel", name: "cancel" },
    { label: t("user"), value: "", name: "user" },
  ];

  const rankingSortList = [
    {
      label: `${t("victoryStreak")}(${t("lifetime")})`,
      name: "streakLifetime",
      value: "streak",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("victoryStreak")}(${t("season")})`,
      name: "streakSeason",
      value: "streak",
      type: "season",
      selected: false,
    },
    {
      label: `${t("challengeConqueror")}(${t("lifetime")})`,
      name: "challengeConquerorLifetime",
      value: "challengeConqueror",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("challengeConqueror")}(${t("season")})`,
      name: "challengeConquerorSeason",
      value: "challengeConqueror",
      type: "season",
      selected: false,
    },
    {
      label: `${t("pyramidClimber")}(${t("lifetime")})`,
      name: "pyramidClimberLifetime",
      value: "pyramidClimber",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("pyramidClimber")}(${t("season")})`,
      name: "pyramidClimberSeason",
      value: "pyramidClimber",
      type: "season",
      selected: false,
    },
    {
      label: `${t("master180")}(${t("lifetime")})`,
      name: "master180Lifetime",
      value: "master180",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("master180")}(${t("season")})`,
      name: "master180Season",
      value: "master180",
      type: "season",
      selected: false,
    },
    {
      label: `${t("grandMaster")}(${t("lifetime")} - ${t("match")})`,
      name: "grandMasterLifetime",
      value: "grandMaster",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("grandMaster")}(${t("season")} - ${t("match")})`,
      name: "grandMasterSeason",
      value: "grandMaster",
      type: "season",
      selected: false,
    },
    {
      label: `${t("friendlyChallenger")}(${t("lifetime")})`,
      name: "friendlyLifetime",
      value: "friendly",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("friendlyChallenger")}(${t("season")})`,
      name: "friendlySeason",
      value: "friendly",
      type: "season",
      selected: false,
    },
    {
      label: `${t("readyForIt")}(${t("lifetime")})`,
      name: "readyLifetime",
      value: "ready",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("readyForIt")}(${t("season")})`,
      name: "readySeason",
      value: "ready",
      type: "season",
      selected: false,
    },
    {
      label: `${t("consistentScorer")}(${t("lifetime")})`,
      name: "consistentScorerLifetime",
      value: "consistentScorer",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("consistentScorer")}(${t("season")})`,
      name: "consistentScorerSeason",
      value: "consistentScorer",
      type: "season",
      selected: false,
    },
    {
      label: `${t("pyramidProtector")}(${t("lifetime")})`,
      name: "pyramidProtectorLifetime",
      value: "pyramidProtector",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("pyramidProtector")}(${t("season")})`,
      name: "pyramidProtectorSeason",
      value: "pyramidProtector",
      type: "season",
      selected: false,
    },
    {
      label: `${t("ironDart")}(${t("lifetime")})`,
      name: "ironDartLifetime",
      value: "ironDart",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("ironDart")}(${t("season")})`,
      name: "ironDartSeason",
      value: "ironDart",
      type: "season",
      selected: false,
    },
    {
      label: `${t("monthlyMaestro")}(${t("lifetime")})`,
      name: "monthlyMaestroLifetime",
      value: "monthlyMaestro",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("monthlyMaestro")}(${t("season")})`,
      name: "monthlyMaestroSeason",
      value: "monthlyMaestro",
      type: "season",
      selected: false,
    },
    {
      label: `${t("breakfast")}(${t("lifetime")})`,
      name: "breakfastLifetime",
      value: "breakfast",
      type: "lifetime",
      selected: true,
    },
    {
      label: `${t("breakfast")}(${t("season")})`,
      name: "breakfastSeason",
      value: "breakfast",
      type: "season",
      selected: true,
    },
    {
      label: `${t("dartEnthusiast")}(${t("lifetime")})`,
      name: "dartLifetime",
      value: "dart",
      type: "lifetime",
      selected: false,
    },
    {
      label: `${t("dartEnthusiast")}(${t("season")})`,
      name: "dartSeason",
      value: "dart",
      type: "season",
      selected: false,
    },
  ];

  const navigation = [
    {
      name: t("home"),
      to: "/",
      current: "/home",
      icon: <HomeIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("infos"),
      to: "/infos",
      current: "/infos",
      children: infoMenus,
      icon: <InformationCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("pyramid"),
      to: "/pyramid-users",
      current: "/pyramid-users",
      icon: <AdjustmentsVerticalIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("events"),
      to: "/events",
      current: "/events",
      icon: <CalendarIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("calendar"),
      to: "/schedule",
      current: "/schedule",
      icon: <CalendarDaysIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("ranking"),
      to: "/ranking",
      current: "/ranking",
      icon: <AdjustmentsVerticalIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("profile"),
      to: "/profile",
      current: "/profile",
      children: profileMenuItems,
      icon: <IdentificationIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("result"),
      to: "/result",
      current: "/result",
      icon: <CircleStackIcon className="w-6 h-6" aria-hidden="true" />,
    },
  ];

  const userMenuItems = [
    { name: t("profile"), to: "/profile/summary" },
    { name: t("price"), to: "/price" },
    { name: t("settings"), to: "/settings" },
  ];

  const priceCards = [
    {
      title: t("basic"),
      price: 2,
      msg: t("buy6Coins"),
    },
    {
      title: t("premium"),
      price: 5,
      msg: t("buy20Coins"),
    },
    {
      title: t("enterprise"),
      price: 10,
      msg: t("buy50Coins"),
    }
  ]

  return {
    infoMenus,
    eventFilterList,
    profileMenuItems,
    navigation,
    userMenuItems,
    rankingSortList,
    priceCards
  };
};
