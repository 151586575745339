import {
  LockClosedIcon,
  CheckCircleIcon,
  ArrowUpCircleIcon,
  LockOpenIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import ToolTip from "../ToolTip/ToolTip";

const AvatarCard = ({ user, avatar, handleLvlUp }) => {
  const { t } = useTranslation();
  return (
    <div
      key={avatar.title}
      className={`w-96 border ${
        avatar.isSelected ? "border-yellow-600 border-2" : "border-yellow-300"
      } p-1 bg-gray-100 dark:bg-gray-900 dark:text-white dark:text-white rounded-md`}
    >
      <div className="flex gap-10 justify-between px-4 py-2 bg-gray-200 dark:bg-gray-800 rounded-md mb-2 text-left">
        <div>
          <p className="font-semibold text-xl">{avatar.title}</p>
          <p className="font-medium text-yellow-500">
            Ranks:{" "}
            <span className="font-semibold text-black  dark:text-white">
              {avatar.ranks}
            </span>
          </p>
        </div>
        <div className="flex items-center justify-center text-center bg-gray-100 dark:bg-gray-900 px-4 rounded-md text-yellow-500">
          XP:
          <span className="font-semibold text-black dark:text-white ml-1">
            {avatar.xp}
          </span>
        </div>
      </div>
      <div className="flex items-center justify-center mb-8">
        <img
          className="rounded-md"
          width={150}
          height={150}
          src={avatar.img}
          alt="avatar"
        />
      </div>
      <div className="flex flex-wrap justify-center gap-4 mb-4">
        {avatar.skillset.map((skill) => (
          <div key={skill.name} className="flex w-44 flex-row">
            <ToolTip
              key={skill.name}
              text={skill.description}
              className="flex-1"
            >
              <p
                className={` text-center font-semibold text-white text-sm ${skill.color} px-1 py-2 rounded-tl-lg rounded-bl-lg`}
              >
                {skill.name}
              </p>
            </ToolTip>

            <div className="flex-1 flex items-center bg-gray-200 dark:bg-gray-800 text-sm px-4 py-2 rounded-tr-lg rounded-br-lg">
              <p>
                {Number(skill.value).toFixed(1)}
                {skill.name === "Scoring" ? "" : "%"}
              </p>
              {skill.name !== "XP Boost" && skill.name !== "Edge" && (
                <ToolTip
                  text={`Required XP to level up: ${skill.requiredXp}`}
                  className="ml-auto"
                >
                  <button
                    className={`${avatar.isSelected ? "visible" : "hidden"}`}
                    onClick={() => handleLvlUp(skill)}
                  >
                    <ArrowUpCircleIcon className="h-4 w-4" />
                  </button>
                </ToolTip>
              )}
            </div>
          </div>
        ))}
      </div>
      <div className="relative">
        <div className="relative h-8 bg-gray-300 dark:bg-gray-800 rounded-md overflow-hidden">
          <div
            className="absolute top-0 left-0 h-full bg-gradient-to-r from-emerald-100 to-emerald-300"
            style={{ width: `${(user.xp / avatar.xp) * 100}%` }}
          ></div>
        </div>
        <div className="absolute flex justify-center top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2">
          {avatar.isLocked ? (
            <ToolTip text={t("locked")}>
              <LockClosedIcon className="h-6 w-6 text-red-500" />
            </ToolTip>
          ) : avatar.isSelected ? (
            <ToolTip text={t("selected")}>
              <CheckCircleIcon className="w-6 h-6 text-green-500" />
            </ToolTip>
          ) : (
            <button className="text-black border border-gray-600 rounded-full w-7 h-7">
              <ToolTip text={t("selectAvatar")}>
                <LockOpenIcon className="w-4 h-4 text-blue-500" />
              </ToolTip>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AvatarCard;
