import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import PyramidMenu from "../components/Pyramid/PyramidMenu";
import WaitingModal from "../components/WaitingModal";
import Loading from "../components/Loading";
import UsersPyramid from "../components/Pyramid/UsersPyramid";
import { useResult } from "../hooks/useResult";

const Pyramid = () => {
  const { t } = useTranslation();
  const {
    results: players,
    isLoading,
    imgSize,
    isOnlineShow,
    selectedPlayer,
    isChallengeLoading,
    onSliderChange,
    setIsOnlineShow,
    setSelectedPlayer,
    sendQuickFight,
    sendScheduledFight,
    handleWaitingCancel,
  } = useResult();

  const { allUsers } = useSelector((state) => state.auth);
  let connectedUsers = allUsers.filter(
    (val) => val.status === "online" || val.status === "occupied"
  );

  return (
    <div className="flex flex-col lg:flex-row gap-4 py-8 px-4">
      {/* mobile */}
      <PyramidMenu
        className="flex w-full px-2 flex-col lg:hidden"
        isOnlineShow={isOnlineShow}
        connectedUsers={connectedUsers}
        setIsOnlineShow={setIsOnlineShow}
        setSelectedPlayer={setSelectedPlayer}
      />
      <div className="flex flex-col w-full py-4 px-2 lg:w-8/12">
        <div className="flex items-center space-x-4 h-[50px]">
          <p className="text-gray-900 dark:text-white">{t("zoomOut")}</p>
          <input
            id="medium-range"
            type="range"
            min={6}
            max={16}
            step={2}
            value={imgSize}
            className="w-full h-2 bg-gray-200 text-green-600 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
            onChange={onSliderChange}
          />
          <p className="text-gray-900 dark:text-white">{t("zoomIn")}</p>
        </div>
        <div className="overflow-y-auto h-[750px] custom-scrollbar py-4 max-h-70-vh divide-y divide-green-300 dark:divide-gray-400">
          {isLoading ? (
            <div className="flex flex-col gap-4">
              <Loading />
              <Loading />
              <Loading />
              <Loading />
            </div>
          ) : (
            <UsersPyramid
              onlineShow={isOnlineShow}
              players={players}
              selectedPlayer={selectedPlayer}
              connectedUsers={connectedUsers}
              imgSize={imgSize}
              sendQuickFight={sendQuickFight}
              sendScheduledFight={sendScheduledFight}
            />
          )}
        </div>
      </div>
      {/* desktop */}
      <PyramidMenu
        className="hidden h-[800px] overflow-hidden overflow-y-auto custom-scrollbar px-2 lg:flex lg:flex-col md:px-6 w-6/12 lg:w-4/12"
        isOnlineShow={isOnlineShow}
        connectedUsers={connectedUsers}
        setIsOnlineShow={setIsOnlineShow}
        setSelectedPlayer={setSelectedPlayer}
      />
      <WaitingModal
        isShow={isChallengeLoading}
        handleCancel={handleWaitingCancel}
      />
    </div>
  );
};

export default Pyramid;
