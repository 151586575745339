import {
  ArrowLeftStartOnRectangleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

import images from "../helper/images";
import useSeason from "../hooks/useSeason";
import { useAuth } from "../hooks/useAuth";
import SideMenuComponent from "./SideMenuComponent";

const Sidebar = ({ onCloseSidebar }) => {
  const { SEASONBG } = images;
  const { t } = useTranslation();
  const { season } = useSeason();
  const { currentSeason } = season;
  const { logout } = useAuth();

  return (
    <div className="w-full py-12 dark:bg-gray-800">
      <div className="flex items-center justify-end px-4">
        <button
          className="relative block md:hidden inline-flex items-center justify-center rounded-md p-2 text-gray-400 hover:bg-gray-300 dark:hover:bg-gray-900 hover:text-white focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
          onClick={onCloseSidebar}
        >
          <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <div>
        <SideMenuComponent />
      </div>
      <div className="relative pt-24">
        <div className="relative flex items-center justify-center">
          <img src={SEASONBG} alt="season-bg" />
          <div className="absolute w-[171px] h-full ml-auto mr-auto flex flex-col item-center justify-center top-0 text-white">
            <p>
              Season {currentSeason?.season} currently running with{" "}
              {currentSeason?.activeUsers} Members
            </p>
            <p className="text-sm text-gray-300 dark:text-gray-400">
              {new Date(currentSeason?.seasonStart).toLocaleDateString()} -{" "}
              {new Date(currentSeason?.seasonEnd).toLocaleDateString()}
            </p>
          </div>
        </div>
      </div>
      <div className="px-6 py-2 pt-12">
        <button
          className="flex gap-6 p-2 items-center rounded-md w-full text-left text-sm font-medium text-gray-900 dark:text-gray-300 hover:bg-gray-100 hover:dark:bg-gray-700 hover:text-gray-900 hover:dark:text-white"
          onClick={logout}
        >
          <span>
            <ArrowLeftStartOnRectangleIcon
              className="h-6 w-6"
              aria-hidden="true"
            />
          </span>
          {t("signOut")}
        </button>
      </div>
    </div>
  );
};

export default Sidebar;
