import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import moment from "moment";

import Loading from "../../components/Loading";
import ScheduleModal from "../../components/Profile/ScheduleModal";
import { useSchedule } from "../../hooks/useSchedule";

const ProfileCalendar = () => {
  const localizer = momentLocalizer(moment);

  const {
    user,
    events,
    currentSchedule,
    isLoading,
    isOpen,
    handleCalendar,
    refreshSchedules,
    onClose,
    onClick,
    onDecline,
  } = useSchedule();

  return (
    <div>
      {isLoading ? (
        <Loading />
      ) : (
        events && (
          <div className="p-8 bg-white dark:bg-gray-800 dark:text-white mt-8 rounded-md">
            <div className="flex">
              <button
                className="border border-green-500 rounded-md mb-8 text-green-500 hover:text-green-600 ml-auto p-2"
                onClick={refreshSchedules}
              >
                <div className="w-8 h-8">
                  <ArrowPathIcon />
                </div>
              </button>
            </div>
            <Calendar
              localizer={localizer}
              events={events}
              startAccessor="start"
              endAccessor="end"
              onSelectEvent={handleCalendar}
              style={{ height: 500 }}
            />
          </div>
        )
      )}
      <ScheduleModal
        isOpen={isOpen}
        user={user}
        currentSchedule={currentSchedule}
        onClose={onClose}
        onClick={onClick}
        onDecline={onDecline}
      />
    </div>
  );
};

export default ProfileCalendar;
