import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchResultAll } from "../app/resultSlice";

export const useCounter = () => {
  const dispatch = useDispatch();
  const { results } = useSelector((state) => state.result);

  const [seasonCount, setSeasonCount] = useState(0);
  const [lifetimeCount, setLifetimeCount] = useState(0);

  useEffect(() => {
    dispatch(fetchResultAll());
  }, [dispatch]);

  useEffect(() => {
    if (results.length) {
      let totalSeasonCount = 0;
      let totalLifetimeCount = 0;
      results.forEach((result) => {
        totalSeasonCount += (result.friendlyChallenger?.season || 0) + (result.readyForIt?.season || 0);
        totalLifetimeCount += (result.friendlyChallenger?.lifetime || 0) + (result.readyForIt?.lifetime || 0);
      });
      setSeasonCount(totalSeasonCount);
      setLifetimeCount(totalLifetimeCount);
    }
  }, [results]);

  return { seasonCount, lifetimeCount };
};
