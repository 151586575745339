import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

import { fetchResultAll } from "../app/resultSlice";
import { updateAllUsers } from "../app/authSlice";
import { postEvent } from "../app/eventSlice";
import { transformTableData } from "../helper/helper";
import { usePrice } from "./usePrice";

import socket from "../socket";

export const useResult = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { results, isLoading } = useSelector((state) => state.result);
  const { user, allUsers } = useSelector((state) => state.auth);
  const {
    customPrice,
    defaultPrice,
    globalCoins,
    updateCustomPrice,
    updateGlobalCoin,
  } = usePrice();

  const [imgSize, setImgSize] = useState(10);
  const [isOnlineShow, setIsOnlineShow] = useState(false);
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [isChallengeLoading, setIsChallengeLoading] = useState(false);
  const [opponent, setOpponent] = useState(null);

  useEffect(() => {
    dispatch(fetchResultAll());
  }, [dispatch]);

  useEffect(() => {
    if (!isChallengeLoading) return;

    const handleQuickAcceptResponse = ({ paymentOption, token, opponent }) => {
      setIsChallengeLoading(false);

      if (paymentOption === "payAll") {
        if (customPrice > 0.5) {
          updateCustomPrice(user._id, "custom", -1);
        } else if (defaultPrice > 0.5) {
          updateCustomPrice(user._id, "default", -1);
        } else if (globalCoins > 0.5) {
          updateGlobalCoin(-1);
        }
      } else {
        if (customPrice > 0) {
          updateCustomPrice(user._id, "custom", -0.5);
        } else if (defaultPrice > 0) {
          updateCustomPrice(user._id, "default", -0.5);
        } else if (globalCoins > 0) {
          updateGlobalCoin(-0.5);
        }
      }

      dispatch(
        postEvent({
          eventType: "quick-token",
          user: user.username,
          targetUser: opponent,
          token,
        })
      );

      const newWindow = window.open(
        `https://lidarts.org/game/create?opponent_name=${encodeURIComponent(
          opponent?.toLowerCase()
        )}`,
        "_blank"
      );

      if (newWindow) {
        console.log("newWindow", newWindow);
      } else {
        console.log("new-window--error-->>");
        toast.warning(
          "Pop-up blocked. Please allow pop-ups and redirects for this site."
        );
      }

      // dynamicCreateAnchorAndNavigater(
      //   `https://lidarts.org/game/create?opponent_name=${encodeURIComponent(
      //     opponent?.toLowerCase()
      //   )}`
      // );

      navigate(`/result/${token}/quick`);
    };

    const handleChallengeDecline = ({ from }) => {
      setIsChallengeLoading(false);

      const occupiedUsers = allUsers.filter(
        (val) => val._id === from || val._id === user?._id
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }
    };

    socket.on("quick-accept-response", handleQuickAcceptResponse);
    socket.on("challenge-decline-response", handleChallengeDecline);

    return () => {
      socket.off("quick-accept-response", handleQuickAcceptResponse);
      socket.off("challenge-decline-response", handleChallengeDecline);
    };
  }, [
    isChallengeLoading,
    customPrice,
    defaultPrice,
    globalCoins,
    navigate,
    updateCustomPrice,
    updateGlobalCoin,
  ]);

  const dynamicCreateAnchorAndNavigater = (url) => {
    let anchorElement = document.createElement("a");
    anchorElement.href = url;
    anchorElement.target = "_blank";
    document.body.appendChild(anchorElement);
    anchorElement.click();
    document.body.removeChild(anchorElement);
  };

  const transformedResults = useMemo(
    () => transformTableData(results),
    [results]
  );

  const onSliderChange = (e) => {
    setImgSize(e.target.value);
  };

  const handleWaitingCancel = () => {
    setIsChallengeLoading(false);
    if (opponent) {
      const occupiedUsers = allUsers.filter(
        (val) =>
          val.username.toLowerCase() === opponent.toLowerCase() ||
          val._id === user?._id
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }

      dispatch(
        postEvent({
          eventType: "cancel",
          user: user.username,
        })
      );

      socket.emit("cancel-challenge", {
        message: `${user?.username} cancelled the challenge.`,
        toId: allUsers.find(
          (val) => val.username?.toLowerCase() === opponent?.toLowerCase()
        )?._id,
        fromId: user._id,
      });
    }
  };

  const sendQuickFight = async (username, challenger, paymentOption) => {
    if (
      paymentOption === "payAll" &&
      customPrice <= 0.5 &&
      defaultPrice <= 0.5 &&
      globalCoins <= 0.5
    ) {
      toast.error("You don't have enough coins to challenge.");
      return;
    } else if (
      paymentOption === "payHalf" &&
      customPrice <= 0 &&
      defaultPrice <= 0 &&
      globalCoins <= 0
    ) {
      toast.error("You don't have enough coins to challenge.");
      return;
    }

    dispatch(
      postEvent({
        eventType: "quick",
        user: challenger,
        targetUser: username,
      })
    );

    setIsChallengeLoading(true);
    setOpponent(username);

    const occupiedUsers = allUsers.filter(
      (val) =>
        val.username.toLowerCase() === username?.toLowerCase() ||
        val.username.toLowerCase() === challenger?.toLowerCase()
    );

    if (occupiedUsers.length > 0) {
      occupiedUsers.forEach((val) => {
        dispatch(updateAllUsers({ ...val, status: "occupied" }));
      });
    }

    socket.emit("challenge", {
      message: `${challenger} has sent you the quick challenge with the ${paymentOption} option.`,
      toId: allUsers?.find(
        (val) => val.username?.toLowerCase() === username?.toLowerCase()
      )?._id,
      fromId: allUsers?.find(
        (val) => val.username?.toLowerCase() === challenger?.toLowerCase()
      )?._id,
      to: username,
      from: challenger,
      paymentOption,
    });
  };

  const sendScheduledFight = (
    selectedDate,
    challenger,
    challengerEmail,
    receiver,
    receiverEmail,
    paymentOption
  ) => {
    dispatch(
      postEvent({
        eventType: "schedule",
        user: challenger,
        targetUser: receiver,
      })
    );

    const opponent = allUsers.find(
      (val) => val.username?.toLowerCase() === receiver?.toLowerCase()
    );

    if (
      paymentOption === "payAll" &&
      customPrice <= 0.5 &&
      defaultPrice <= 0.5 &&
      globalCoins <= 0.5
    ) {
      toast.error("You don't have enough coins to challenge.");
      return;
    } else if (
      paymentOption === "payHalf" &&
      customPrice <= 0 &&
      defaultPrice <= 0 &&
      globalCoins <= 0
    ) {
      toast.error("You don't have enough coins to challenge.");
      return;
    } else if (!opponent) {
      toast.error("User not found.");
      return;
    } else if (
      paymentOption === "payHalf" &&
      opponent?.customBalance <= 0 &&
      opponent?.defaultBalance <= 0
    ) {
      toast.error("Opponent doesn't have enough coins to challenge.");
      return;
    }

    if (paymentOption === "payAll") {
      if (customPrice > 0.5) {
        console.log("customPrice", customPrice);
        updateCustomPrice(user._id, "custom", -1);
      } else if (defaultPrice > 0.5) {
        console.log("defaultPrice", defaultPrice);
        updateCustomPrice(user._id, "default", -1);
      } else if (globalCoins > 0.5) {
        updateGlobalCoin(-1);
      }
    } else {
      if (customPrice > 0) {
        updateCustomPrice(user._id, "custom", -0.5);
      } else if (defaultPrice > 0) {
        updateCustomPrice(user._id, "default", -0.5);
      } else if (globalCoins > 0) {
        updateGlobalCoin(-0.5);
      }

      if (opponent?.customBalance > 0) {
        updateCustomPrice(opponent._id, "custom", -0.5);
      } else if (opponent?.defaultBalance > 0) {
        updateCustomPrice(opponent._id, "custom", -0.5);
      } else if (globalCoins > 0) {
        updateGlobalCoin(-0.5);
      }
    }

    socket.emit("schedule-challenge", {
      message: `${challenger} has sent you the scheduled challenge. Challenge date: ${selectedDate}. Payment option: ${paymentOption}.`,
      to: allUsers.find(
        (val) => val.username?.toLowerCase() === receiver?.toLowerCase()
      )?._id,
      date: selectedDate,
      challenger,
      receiver,
      receiverEmail,
      challengerEmail,
      paymentOption,
    });
  };

  return {
    transformedResults,
    results,
    isLoading,
    imgSize,
    isOnlineShow,
    selectedPlayer,
    isChallengeLoading,
    onSliderChange,
    setIsOnlineShow,
    setSelectedPlayer,
    sendQuickFight,
    sendScheduledFight,
    handleWaitingCancel,
  };
};
