import { Calendar, momentLocalizer } from "react-big-calendar";
import { ArrowPathIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

import { useSchedule } from "../hooks/useSchedule";
import Loading from "../components/Loading";
import UpcomingEvents from "../components/Schedule/UpcomingEvents";

const Schedule = () => {
  const localizer = momentLocalizer(moment);

  const { allEvents, events, isLoading, user, refreshSchedules } =
    useSchedule();

  return (
    <div className="my-20">
      {isLoading ? (
        <div className="flex flex-col space-y-4">
          <Loading />
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </div>
      ) : (
        <div className="p-8 flex dark:text-white lg:px-12 gap-8 flex-col md:flex-row">
          <div>
            <div className="flex">
              <button
                className="border border-green-500 rounded-md mb-8 text-green-500 hover:text-green-600 ml-auto p-2"
                onClick={refreshSchedules}
              >
                <div className="w-6 h-6">
                  <ArrowPathIcon />
                </div>
              </button>
            </div>
            <Calendar
              localizer={localizer}
              events={allEvents}
              startAccessor="start"
              endAccessor="end"
              style={{ height: 500 }}
            />
          </div>
          <UpcomingEvents allEvents={allEvents} myEvents={events} />
        </div>
      )}
    </div>
  );
};

export default Schedule;
