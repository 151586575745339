import { useTranslation } from "react-i18next";
import { EnvelopeIcon, UserIcon } from "@heroicons/react/24/solid";

import CustomInputComponent from "../CustomInput";
import SpinnerLoading from "../SpinnerLoading";
import { CustomButton } from "../CustomButton";
import DiscordIcon from "../SVG/DiscordIcon";
import FacebookIcon from "../SVG/FacebookIcon";
import TwitterIcon from "../SVG/TwitterIcon";
import InstagramIcon from "../SVG/InstagramIcon";

const PersonalForms = ({
  isLoading,
  userData,
  onUserDataChange,
  handleUserInfo,
}) => {
  const { t } = useTranslation();

  return (
    <form onSubmit={handleUserInfo}>
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div className="col-span-full">
          <CustomInputComponent
            name="email"
            type="email"
            placeholder="you@example.com"
            required={true}
            value={userData.email}
            onChange={onUserDataChange}
            label={
              <label
                htmlFor="email"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {t("email")}
              </label>
            }
            icon={
              <EnvelopeIcon
                className="h-5 w-5 text-gray-400"
                aria-hidden="true"
              />
            }
          />
        </div>

        <div className="col-span-full">
          <CustomInputComponent
            name="username"
            type="text"
            placeholder={t("enterUsername")}
            required={true}
            value={userData.username}
            onChange={onUserDataChange}
            label={
              <label
                htmlFor="username"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                {t("username")}
              </label>
            }
            icon={
              <UserIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
            }
          />
        </div>

        <div className="col-span-full">
          <CustomInputComponent
            name="discord"
            type="text"
            placeholder={t("enterYourDiscordLink")}
            required={false}
            value={userData?.discord}
            onChange={onUserDataChange}
            label={
              <label
                htmlFor="discord"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Discord
              </label>
            }
            icon={<DiscordIcon />}
          />
        </div>

        <div className="col-span-full">
          <CustomInputComponent
            name="twitter"
            type="text"
            placeholder={t("enterYourTwitterLink")}
            required={false}
            value={userData?.twitter}
            onChange={onUserDataChange}
            label={
              <label
                htmlFor="twitter"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Twitter
              </label>
            }
            icon={<TwitterIcon />}
          />
        </div>

        <div className="col-span-full">
          <CustomInputComponent
            name="facebook"
            type="text"
            placeholder={t("enterYourFacebookLink")}
            required={false}
            value={userData.facebook}
            onChange={onUserDataChange}
            label={
              <label
                htmlFor="facebook"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Facebook
              </label>
            }
            icon={<FacebookIcon />}
          />
        </div>

        <div className="col-span-full">
          <CustomInputComponent
            name="instagram"
            type="text"
            placeholder={t("enterYourInstagramLink")}
            required={false}
            value={userData?.instagram}
            onChange={onUserDataChange}
            label={
              <label
                htmlFor="instagram"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
              >
                Instagram
              </label>
            }
            icon={<InstagramIcon />}
          />
        </div>
      </div>

      {isLoading ? (
        <SpinnerLoading />
      ) : (
        <div className="mt-8 flex justify-center md:justify-start">
          <CustomButton
            type="submit"
            className="rounded-md py-2 px-3 text-sm font-semibold shadow-sm"
          >
            {t("save")}
          </CustomButton>
        </div>
      )}
    </form>
  );
};

export default PersonalForms;
