import { useState, useEffect, useMemo, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";

import socket from "../socket";
import { usePrice } from "./usePrice";
import { addUser, fetchAllusers, updateAllUsers } from "../app/authSlice";
import { postEvent } from "../app/eventSlice";
import notificationSound from "../assets/audio/notification.mp3";

export const useSocketEvent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userState = useSelector((state) => state.auth);
  const {
    customPrice,
    defaultPrice,
    globalCoins,
    updateCustomPrice,
    updateGlobalCoin,
  } = usePrice();

  // const userID = userState?.user?._id;
  const users = userState?.allUsers;
  const user = userState?.user;

  // const [users, setUsers] = useState([]);
  const [notifications, setNotifications] = useState([]);
  const [toastId, setToastId] = useState(null);

  const selectedUser = useMemo(
    () => users.find((val) => val.userID === socket.userID),
    [users]
  );

  const audio = new Audio(notificationSound);

  const playAudio = () => {
    audio.play().catch((err) => console.log("Audio--err-->>", err));
  };

  const handleErr = useCallback((err) => {
    console.log("Socket--err-->>", err);
  }, []);

  useEffect(() => {
    dispatch(fetchAllusers());
  }, []);

  useEffect(() => {
    const handleUserID = ({ userID }) => {
      console.log("user_id--->>>", userID);
      socket.userID = userID;
    };

    socket.on("user_id", handleUserID);
    socket.on("connect_error", handleErr);

    return () => {
      socket.off("connect_error", handleErr);
      socket.off("user_id", handleUserID);
    };
  }, [user, handleErr]);

  useEffect(() => {
    const updateUsersStatus = (status) => {
      dispatch(updateAllUsers({ ...user, status }));
    };

    const handleUserStatusUpdate = ({ updatedUsersList }) => {
      updatedUsersList.forEach((user) => {
        dispatch(updateAllUsers(user));
      });
      console.log("receivedusers=>>>>", updatedUsersList);
    };

    const updateUsers = async (receivedUsers) => {
      receivedUsers.forEach((user) => {
        const existingUserIndex = users.findIndex(
          (existingUser) => existingUser._id === user._id
        );

        if (existingUserIndex !== -1) {
          dispatch(updateAllUsers(user));
        } else {
          dispatch(addUser(user));
        }
      });
    };

    const updateUserConnectionStatus = (isConnected) => (user) => {
      const { status, userID } = user;
      let connectedUser = {
        ...users.find((val) => val._id === userID),
        status,
      };
      dispatch(updateAllUsers(connectedUser));
    };

    const handleNotifications = (notifications) => {
      setNotifications(notifications);
    };

    const handleNotification = (notification) => {
      playAudio();

      handleDesktopNotification(notification);

      if (!toast.isActive("notification")) {
        toast.info(notification.message, { toastId: "notification" });
      }

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);
    };

    const handleQuickResponse = (
      toId,
      opponent,
      paymentOption,
      notification,
      token
    ) => {
      if (paymentOption === "payHalf") {
        if (customPrice > 0) {
          updateCustomPrice(user._id, "custom", -0.5);
        } else if (defaultPrice > 0) {
          updateCustomPrice(user._id, "default", -0.5);
        } else if (globalCoins > 0) {
          updateGlobalCoin(-0.5);
        } else {
          toast.error("You don't have enough coins to accept the challenge.");
          socket.emit("challenge-decline", {
            message: `${user?.username} can't accept the challenge due to insufficient coins`,
            to: toId,
          });
          return;
        }
      }

      socket.emit("quick-accept", { toId, opponent, paymentOption, token });

      handleNotificationClick(notification);

      window.open(`https://lidarts.org`, "_blank");

      navigate(`/result/${token}/quick`);
    };

    const handleCancelChallengeResponse = ({ notification, fromId }) => {
      handleNotification(notification);

      const occupiedUsers = users.filter(
        (val) => val._id === user._id || val._id === fromId
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }

      if (toast.isActive(toastId)) {
        toast.dismiss(toastId);
        toast.info(notification.message);
      }
    };

    const handleChallengeDecline = (notification, fromId) => {
      handleNotificationClick(notification);

      const occupiedUsers = users.filter(
        (val) => val._id === user._id || val._id === fromId
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "online" }));
        });
      }

      dispatch(
        postEvent({
          eventType: "decline",
          user: user.username,
          targetUser: users.find((val) => val._id === fromId)?.username,
        })
      );

      socket.emit("challenge-decline", {
        message: `${user?.username} has declined the challenge`,
        to: fromId,
        from: user._id,
      });
    };

    const handleChallengeDeclineResponse = ({ notification }) => {
      handleNotification(notification);
    };

    const requestNotificationPermission = async () => {
      if (Notification.permission === "granted") {
        return true;
      } else if (Notification.permission !== "denied") {
        const permission = await Notification.requestPermission();
        return permission === "granted";
      }
      return false;
    };

    const showNotification = (title, options) => {
      if (Notification.permission === "granted") {
        new Notification(title, options);
      }
    };

    const handleDesktopNotification = async (notification) => {
      const hasPermission = await requestNotificationPermission();
      if (hasPermission && document.visibilityState === "hidden") {
        showNotification("Fight Club Notification", {
          body: notification?.message,
        });
      }
    };

    const handleQuickNotification = ({
      notification,
      token,
      to,
      fromId,
      paymentOption,
    }) => {
      playAudio();
      const CustomToast = ({ closeToast }) => (
        <div>
          <p>{notification.message}</p>
          <div className="flex items-center flex-end gap-4">
            <button
              className="bg-green-400 text-white hover:bg-green-500 px-4 py-2 rounded-lg"
              onClick={() => {
                handleQuickResponse(
                  fromId,
                  to,
                  paymentOption,
                  notification,
                  token
                );
                closeToast();
              }}
            >
              {t("accept")}
            </button>
            <button
              className="bg-green-400 text-white hover:bg-green-500 px-4 py-2 rounded-lg"
              onClick={() => {
                handleChallengeDecline(notification, fromId);
                closeToast();
              }}
            >
              {t("decline")}
            </button>
          </div>
        </div>
      );

      const occupiedUsers = users.filter(
        (val) =>
          val.username.toLowerCase() === to?.toLowerCase() || val._id === fromId
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "occupied" }));
        });
      }

      setToastId(fromId);
      if (!toast.isActive(fromId)) {
        toast.info(<CustomToast />, {
          autoClose: false,
          closeOnClick: false,
          toastId: fromId,
        });
      }

      handleDesktopNotification(notification);

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);
      playAudio();
    };

    const handleScheduleNotification = ({ notification }) => {
      if (!toast.isActive("schedule-notification"))
        toast.info(notification.message, { toastId: "schedule-notification" });

      handleDesktopNotification(notification);

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);
      console.log("schedule-notification---->>", notification.message);
    };

    const handleScheduleReply = ({ fromId, token }) => {
      navigate(`/result/${token}/schedule`);

      window.open(`https://lidarts.org`, "_blank");
    };

    const handleTopChallengeResponse = ({ notification }) => {
      if (!toast.isActive("top-notification"))
        toast.info(notification.message, { toastId: "top-notification" });

      handleDesktopNotification(notification);

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        notification,
      ]);

      navigate(`/top-challenge-result`);

      window.open(`https://lidarts.org`, "_blank");
    };

    const handleConnect = () => updateUsersStatus("online");
    const handleDisconnect = () => updateUsersStatus("offline");
    const handleUsers = updateUsers;
    const handleUserConnected = updateUserConnectionStatus(true);
    const handleUserDisconnected = updateUserConnectionStatus(false);

    socket.on("connect", handleConnect);
    socket.on("disconnect", handleDisconnect);
    socket.on("users", handleUsers);
    socket.on("statusUpdate", handleUserStatusUpdate);
    socket.on("notifications", handleNotifications);
    socket.on("notification", handleNotification);
    socket.on("quick-notification", handleQuickNotification);
    socket.on("cancel-challenge-response", handleCancelChallengeResponse);
    socket.on("user connected", handleUserConnected);
    socket.on("user disconnected", handleUserDisconnected);
    socket.on("challenge-decline-response", handleChallengeDeclineResponse);
    socket.on("schedule-notification", handleScheduleNotification);
    socket.on("schedule-create-challenge-response", handleScheduleReply);
    socket.on("top-challenge-response", handleTopChallengeResponse);

    return () => {
      socket.off("connect", handleConnect);
      socket.off("disconnect", handleDisconnect);
      socket.off("users", handleUsers);
      socket.off("statusUpdate", handleUserStatusUpdate);
      socket.off("notifications", handleNotifications);
      socket.off("notification", handleNotification);
      socket.off("quick-notification", handleQuickNotification);
      socket.off("cancel-challenge-response", handleCancelChallengeResponse);
      socket.off("user connected", handleUserConnected);
      socket.off("user disconnected", handleUserDisconnected);
      socket.off("challenge-decline-response", handleChallengeDeclineResponse);
      socket.off("schedule-notification", handleScheduleNotification);
      socket.off("schedule-create-challenge-response", handleScheduleReply);
      socket.off("top-challenge-response", handleTopChallengeResponse);
    };
  }, [
    t,
    dispatch,
    selectedUser,
    customPrice,
    defaultPrice,
    globalCoins,
    user,
    navigate,
    updateCustomPrice,
    updateGlobalCoin,
  ]);

  const handleAllNotificationsRead = () => {
    // Mark all notifications as read
    socket.emit("allNotificationsRead");

    // Remove all notifications from the state
    setNotifications([]);
  };

  const handleNotificationClick = (notification) => {
    // Mark the notification as read
    socket.emit("notificationRead", notification._id);

    // Remove the notification from the state
    setNotifications((prevNotifications) =>
      prevNotifications.filter((n) => n._id !== notification._id)
    );
  };

  return {
    users,
    notifications,
    handleAllNotificationsRead,
    handleNotificationClick,
  };
};
