import { useTranslation } from "react-i18next";

import AvatarUpload from "./AvatarUpload";
import PersonalForms from "./PersonalForms";
import { useSettings } from "../../hooks/useSettings";

const PersonalComponent = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    preview,
    userData,
    handleSettingAvatar,
    onUserDataChange,
    handleUserInfo,
  } = useSettings();
  return (
    <div className="grid m-auto max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
          {t("personalInformation")}
        </h2>
      </div>

      <div className="md:col-span-2">
        <AvatarUpload
          isLoading={isLoading}
          preview={preview}
          handleSettingAvatar={handleSettingAvatar}
        />
        <PersonalForms
          isLoading={isLoading}
          userData={userData}
          onUserDataChange={onUserDataChange}
          handleUserInfo={handleUserInfo}
        />
      </div>
    </div>
  );
};

export default PersonalComponent;
