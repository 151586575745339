import { useTranslation } from "react-i18next";

import SpinnerLoading from "../SpinnerLoading";

const AvatarUpload = ({ isLoading, preview, handleSettingAvatar }) => {
  const { t } = useTranslation();
  return (
    <form encType="multipart/form-data">
      <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
        <div className="col-span-full flex items-center gap-x-8">
          {preview === null ? (
            <img
              src="https://www.f-cdn.com/assets/main/en/assets/unknown.png?image-optimizer=force&format=webply&width=336 1x"
              alt=""
              className="h-24 w-24 flex-none rounded-lg dark:bg-gray-800 object-cover"
            />
          ) : (
            <img
              src={preview}
              alt=""
              className="h-24 w-24 flex-none rounded-lg dark:bg-gray-800 object-cover"
            />
          )}
          <div>
            {isLoading ? (
              <SpinnerLoading />
            ) : (
              <label
                htmlFor="avatar-upload"
                className="rounded-md bg-green-600 text-white cursor-pointer px-3 py-1.5 hover:bg-green-500"
              >
                {t("changeAvatar")}
                <input
                  type="file"
                  name="avatar-upload"
                  id="avatar-upload"
                  className="hidden"
                  accept=".png, .jpb, .jpeg"
                  onChange={handleSettingAvatar}
                />
              </label>
            )}

            <p className="mt-2 text-xs leading-5 text-gray-600 dark:text-gray-400">
              JPG, GIF or PNG. 1MB max.
            </p>
          </div>
        </div>
      </div>
    </form>
  );
};

export default AvatarUpload;
