import { useMemo } from "react";

import CustomModal from "../CustomModal";
import images from "../../helper/images";
import Confetti from "../Confetti";
import AchievementModalBoard from "./AchievementModalBoard";
import UserAvatar from "./UserAvatar";

const ResultSummaryModal = ({ isOpen, onClose, summary, result }) => {
  const wonUser = useMemo(
    () =>
      result?.user1?.won > result?.user2?.won ? result?.user1 : result?.user2,
    [result]
  );
  const wonSummary = useMemo(
    () =>
      result?.user1?.won > result?.user2?.won ? summary?.user1 : summary?.user2,
    [summary, result]
  );
  const loseUser = useMemo(
    () =>
      result?.user1?.won > result?.user2?.won ? result?.user2 : result?.user1,
    [result]
  );
  const loseSummary = useMemo(
    () =>
      result?.user1?.won > result?.user2?.won ? summary?.user2 : summary?.user1,
    [summary, result]
  );
  return (
    <CustomModal isOpen={isOpen} onClose={onClose}>
      <div className="flex flex-wrap gap-4 z-50">
        <div className="w-full sm:w-48p">
          <div className="relative">
            <UserAvatar avatar={wonUser?.init?.avatar} name={wonUser?.name} />
            <img
              className="absolute top-0 w-8 h-8"
              src={images.FIRSTMEDAL}
              alt="first-medal"
            />
            <img
              className="absolute bottom-0 w-8 h-8"
              src={images.TROPHY}
              alt="first-medal"
            />
            <img
              className="absolute -top-4 left-1/2 -translate-x-1/2 w-8 h-8"
              src={images.GOLDCROWN}
              alt="first-medal"
            />
          </div>
          {wonSummary.length ? <AchievementModalBoard data={wonSummary} /> : ""}
        </div>
        <div className="w-full sm:w-48p">
          <div className="relative">
            <UserAvatar avatar={loseUser?.init?.avatar} name={loseUser?.name} />
            <img
              className="absolute top-0 right-0 w-8 h-8"
              src={images.SNDBADGE}
              alt="first-medal"
            />
          </div>
          {loseSummary.length ? (
            <AchievementModalBoard data={loseSummary} />
          ) : (
            ""
          )}
        </div>
        <div className="flex w-full">
          <button
            type="button"
            className="inline-flex mx-auto justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
            onClick={onClose}
          >
            Close
          </button>
        </div>
        <Confetti />
      </div>
    </CustomModal>
  );
};

export default ResultSummaryModal;
