import { useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import images from "../../helper/images";
import { fetchSchedule } from "../../app/scheduleSlice";

const HomeScheduleSlider = () => {
  const { HOMESCHEDULEBG } = images;
  const { currentSeason } = useSelector((state) => state.season);
  const { schedules } = useSelector((state) => state.schedule);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchSchedule());
  }, [dispatch]);

  const daysLeft = () => {
    const date1 = new Date(currentSeason?.seasonEnd);
    const date2 = new Date();
    const differenceInTime = date1.getTime() - date2.getTime();
    return Math.ceil(differenceInTime / (1000 * 3600 * 24));
  };

  return (
    <div className="bg-white p-6 rounded-lg dark:bg-[#171927] dark:text-white">
      <div>
        <div className="relative mb-4">
          <div className="flex w-full">
            <img
              className="w-full"
              src={HOMESCHEDULEBG}
              alt="home-schedule-bg"
            />
          </div>
          <div className="absolute bottom-0 w-1/2 p-2 rounded-bl-2xl rounded-tr-2xl">
            <p className="text-white font-semibold text-2xl z-10">
              {daysLeft()} days left
            </p>
            <div className="absolute top-0 left-0 bg-gradient-to-bl from-black to-white w-full h-full opacity-50 rounded-bl-2xl rounded-tr-2xl"></div>
          </div>
        </div>
        <p className="text-left font-semibold text-xl xl:text-2xl p-2">
          Season {currentSeason?.season}
        </p>

        <div className="flex items-center">
          <p className="font-normal text-md px-2">
            Schedules: {schedules?.length}
          </p>
          <Link
            className="p-2 border border-green-500 rounded-md ml-auto"
            to="/schedule"
          >
            Calendar
          </Link>
        </div>
      </div>
    </div>
  );
};

export default HomeScheduleSlider;
