import React, { useState, useEffect } from "react";

function AnimatedCounter({ targetValue, duration }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 0;
    const increment = (targetValue - start) / (duration / 10);

    const interval = setInterval(() => {
      if (start < targetValue) {
        start += increment;
        setCount(Math.round(start));
      } else {
        clearInterval(interval);
      }
    }, 10);

    return () => clearInterval(interval);
  }, [targetValue, duration]);

  return (
    <div className="text-4xl font-bold transition-all duration-500">
      {count}
    </div>
  );
}

export default AnimatedCounter;
