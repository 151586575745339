import { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import { fetchResult, fetchProfile, updateProfile } from "../app/resultSlice";
import { transformSummaryData, cntAchievements } from "../helper/profileHelper";
import http from "../helper/http-client";

export const useSummary = () => {
  const dispatch = useDispatch();
  const auth = useSelector((state) => state.auth);
  const resultState = useSelector((state) => state.result);
  const { user } = auth;
  const { result, profile, isLoading } = resultState;
  const [formData, setFormData] = useState({ profile: profile });
  const [lastLogin, setLastLogin] = useState(null);

  const [isEditable, setIsEditable] = useState(false);

  useEffect(() => {
    dispatch(fetchResult(user?.username));
    dispatch(fetchProfile(user?._id));
    setFormData({ profile: profile });

    const getLastLoginDate = async () => {
      try {
        const res = await http.get(`/auth/get-last-login-date/${user?.username}`);
        setLastLogin(res.data);
      } catch (err) {
        console.log("err-->>", err);
      }
    };

    getLastLoginDate();
  }, [dispatch, user?.username, user?._id, profile]);

  const saveProfile = () => {
    setIsEditable(false);
    dispatch(updateProfile({ userId: user?._id, profile: formData?.profile }));
  };

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const summaryData = useMemo(() => transformSummaryData(result), [result]);
  const cntAchievementsNo = useMemo(() => cntAchievements(result), [result]);

  return {
    isLoading,
    user,
    profile,
    summaryData,
    formData,
    cntAchievementsNo,
    isEditable,
    lastLogin,
    setIsEditable,
    saveProfile,
    onChange,
  };
};
