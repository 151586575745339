import { useEffect } from "react";
import { useSelector } from "react-redux";

import socket from "../socket";
import http from "../helper/http-client";

export const useSocketConnection = () => {
  const userState = useSelector((state) => state.auth);
  const user = userState?.user;

  useEffect(() => {
    const updateLoginStatus = async () => {
      try {
        await http.post("/auth/update-last-login-date", {
          username: user?.username,
        });
      } catch (err) {
        console.log("err-->>", err);
      }
    };
    
    if (user) {
      socket.auth = { userID: user?._id, username: user?.username };
      socket.connect();
      updateLoginStatus();
    }

    return () => {
      socket.disconnect();
    };
  }, [user]);
};
