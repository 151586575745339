const CardBoard = ({ data }) => {
  const { matchAvg = 0, doubles = 0, first9Avg = 0, fights = 0 } = data;
  return (
    <div>
      <div className="py-2 flex justify-between">
        <p className="p-2 text-xl font-semibold">Average</p>
        <p className="p-2 text-xl font-medium">{matchAvg}</p>
      </div>
      <div className="py-2 flex justify-between">
        <p className="p-2 text-xl font-semibold">Doubles</p>
        <p className="p-2 text-xl font-medium">{doubles}</p>
      </div>
      <div className="py-2 flex justify-between">
        <p className="p-2 text-xl font-semibold">First 9</p>
        <p className="p-2 text-xl font-medium">{first9Avg}</p>
      </div>
      <div className="py-2 flex justify-between">
        <p className="p-2 text-xl font-semibold">Fights</p>
        <p className="p-2 text-xl font-medium">{fights}</p>
      </div>
    </div>
  );
};

const SummaryStaticCard = ({ result }) => {
  return (
    <div className="flex flex-wrap gap-4 lg:justify-between">
      <div className="border dark:bg-black border-t-4 border-t-green-600 dark:border-yellow-600 dark:border-t-green-600 p-4 rounded-md w-full lg:w-48p">
        <p className="font-bold text-left text-2xl border border-0 border-b-2 pb-2">
          Overall
        </p>
        <CardBoard data={result?.overall} />
      </div>
      <div className="dark:bg-black border border-t-4 border-t-green-600 dark:border-yellow-600 dark:border-t-green-600 p-4 rounded-md w-full lg:w-48p">
        <p className="font-bold text-left text-2xl border border-0 border-b-2 pb-2">
          Current Month
        </p>
        <CardBoard data={result?.season} />
      </div>
    </div>
  );
};

export default SummaryStaticCard;
