import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";

const UpcomingEvents = ({ allEvents, myEvents }) => {
  const { t } = useTranslation();

  const [isGlobal, setIsGlobal] = useState(true);

  const displayEvents = useMemo(
    () => (isGlobal ? allEvents : myEvents),
    [isGlobal, allEvents, myEvents]
  );

  return (
    <div className="flex flex-col items-center w-full">
      <h2 className="text-2xl font-bold my-4">{t("upcomingEvents")}</h2>
      <div className="flex items-center gap-4">
        <button
          className="border bg-green-600 hover:bg-green-500 text-white dark:bg-gray-700 dark:hover:bg-gray-900 py-2 px-4 font-semibold rounded-lg"
          onClick={() => setIsGlobal(true)}
        >
          {t("global")}
        </button>
        <button
          className="border bg-green-600 hover:bg-green-500 text-white dark:bg-gray-700 dark:hover:bg-gray-900 py-2 px-4 font-semibold rounded-lg"
          onClick={() => setIsGlobal(false)}
        >
          {t("forYou")}
        </button>
      </div>
      <ul>
        {displayEvents?.map((event) => (
          <li key={event.id} className="my-2 text-left">
            <span className="font-bold">{event.title}</span> -{" "}
            {moment(event.start).format("MMMM Do YYYY, h:mm a")}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UpcomingEvents;
