import { useFightResult } from "../hooks/useFightResult";
import TopResultForm from "../components/Result/TopResultForm";
import ResultSummaryModal from "../components/Result/ResultSummaryModal";
import FightResult from "../components/Result/FightResult";

const TopResultPage = () => {
  const {
    isLoading,
    fightResult,
    earnedAchievement,
    isSummaryModalOpen,
    onChange,
    onCloseSummaryMdal,
    handleSubmit,
  } = useFightResult();
  return (
    <div className="px-4 py-8">
      <TopResultForm
        isLoading={isLoading}
        onChange={onChange}
        handleSubmit={handleSubmit}
      />
      <FightResult isLoading={isLoading} result={fightResult} />
      {earnedAchievement && (
        <ResultSummaryModal
          isOpen={isSummaryModalOpen}
          onClose={onCloseSummaryMdal}
          result={fightResult}
          summary={earnedAchievement}
        />
      )}
    </div>
  );
};

export default TopResultPage;
