import { useEffect } from "react";
import {
  EnvelopeIcon,
  LockClosedIcon,
  UserIcon,
} from "@heroicons/react/24/solid";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

import CustomInputComponent from "../components/CustomInput";
import SpinnerLoading from "../components/SpinnerLoading";
import images from "../helper/images";
import { isVariableEmpty } from "../helper/helper";
import { useForm } from "../hooks/useForm";
import { useAuth } from "../hooks/useAuth";

const SignUp = () => {
  const navigate = useNavigate();
  const [authData, onChange] = useForm({
    username: "",
    email: "",
    password: "",
    firstname: "",
    lastname: "",
    avatar: null,
  });
  const { register, handleAvatar, auth } = useAuth();
  const { isLoading, error } = auth;

  useEffect(() => {
    if (error) {
      toast.error(error);
    }
  }, [error]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (authData.password !== authData.password2) {
      toast.warning("Passwords do not match");
      return;
    }
    register(authData);
  };

  const handleAvatarUpload = async (e) => {
    const url = await handleAvatar(e);
    onChange({ avatar: url });
  };

  return (
    <>
      <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white dark:bg-gray-800 text-black dark:text-white pb-40">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-10 w-auto"
            src={images.LOGO}
            alt="Your Company"
          />
          <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-black dark:text-white">
            Create your account
          </h2>
        </div>

        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]">
          <div className="bg-white dark:bg-gray-800 border border-gray-100 dark:border-gray-700 text-black dark:text-white px-6 py-12 shadow sm:rounded-lg sm:px-12">
            <form className="space-y-6" onSubmit={handleSubmit}>
              {isLoading ? (
                <SpinnerLoading />
              ) : (
                <div className="col-span-full flex items-center gap-x-8">
                  {isVariableEmpty(authData?.avatar) ? (
                    <img
                      src="https://www.f-cdn.com/assets/main/en/assets/unknown.png?image-optimizer=force&format=webply&width=336 1x"
                      alt=""
                      className="h-24 w-24 flex-none rounded-lg dark:bg-gray-800 object-cover"
                    />
                  ) : (
                    <img
                      src={authData?.avatar}
                      alt=""
                      className="h-24 w-24 flex-none rounded-lg dark:bg-gray-800 object-cover"
                    />
                  )}
                  <div>
                    <label
                      htmlFor="avatar-upload"
                      className="rounded-md bg-green-600 text-white cursor-pointer px-3 py-1.5 hover:bg-green-500"
                    >
                      Change avatar
                      <input
                        type="file"
                        name="avatar-upload"
                        id="avatar-upload"
                        className="hidden"
                        accept=".png, .jpb, .jpeg"
                        onChange={handleAvatarUpload}
                      />
                    </label>

                    <p className="mt-2 text-xs leading-5 text-gray-600 dark:text-gray-400">
                      JPG, GIF or PNG. 1MB max.
                    </p>
                  </div>
                </div>
              )}

              <CustomInputComponent
                name="username"
                type="text"
                placeholder="Enter username"
                required={true}
                // errors={errors}
                onChange={onChange}
                label={
                  <label
                    htmlFor="user"
                    className="block text-sm text-left font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Username
                  </label>
                }
                icon={
                  <UserIcon
                    className="block h-5 w-5 font-bold focus:text-form-color"
                    aria-hidden="true"
                  />
                }
              />
              <CustomInputComponent
                name="email"
                type="email"
                placeholder="Enter email"
                required={true}
                // errors={errors}
                onChange={onChange}
                label={
                  <label
                    htmlFor="email"
                    className="block text-sm text-left font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    E-Mail-Adress
                  </label>
                }
                icon={
                  <EnvelopeIcon
                    className="block h-5 w-5 font-bold focus:text-form-color"
                    aria-hidden="true"
                  />
                }
              />
              <CustomInputComponent
                name="password"
                type="password"
                placeholder="Enter password"
                required={true}
                // errors={errors}
                onChange={onChange}
                label={
                  <label
                    htmlFor="password"
                    className="block text-sm text-left font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Password
                  </label>
                }
                icon={
                  <LockClosedIcon
                    className="block h-5 w-5 font-bold focus:text-form-color"
                    aria-hidden="true"
                  />
                }
              />
              <CustomInputComponent
                name="password2"
                type="password"
                placeholder="Confirm password"
                required={true}
                // errors={errors}
                onChange={onChange}
                label={
                  <label
                    htmlFor="password confirm"
                    className="block text-sm text-left font-medium leading-6 text-gray-900 dark:text-white"
                  >
                    Password confirm
                  </label>
                }
                icon={
                  <LockClosedIcon
                    className="block h-5 w-5 font-bold focus:text-form-color"
                    aria-hidden="true"
                  />
                }
              />

              <div>
                {isLoading ? (
                  <SpinnerLoading />
                ) : (
                  <button
                    type="submit"
                    className="flex justify-center justify-center bg-green-600 text-base font-semibold text-white hover:bg-green-500 focus:outline-none focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-500 active:text-white/70 w-full px-3 py-1.5 rounded-md"
                  >
                    Register
                  </button>
                )}
              </div>
            </form>
            <div className="font-normal text-md text-gray-900 dark:text-white">
              Already have an account?{" "}
              <span
                className="text-green-700 font-semibold cursor-pointer select-none hover:underline"
                onClick={() => navigate("/login")}
              >
                Log in
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SignUp;
