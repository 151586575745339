import { useTranslation } from "react-i18next";
import { LockClosedIcon } from "@heroicons/react/24/solid";

import CustomInputComponent from "../CustomInput";
import SpinnerLoading from "../SpinnerLoading";
import { CustomButton } from "../CustomButton";
import { useSettings } from "../../hooks/useSettings";

const PasswordComponent = () => {
  const { t } = useTranslation();
  const { isLoading, onChangePwd, handlePwdChange } = useSettings();
  return (
    <div className="grid max-w-7xl m-auto grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
      <div>
        <h2 className="text-base font-semibold leading-7 text-gray-900 dark:text-white">
          {t("changePassword")}
        </h2>
        <p className="mt-1 text-sm leading-6 text-gray-600 dark:text-gray-400">
          {t("updatePasswordAssociatedWithYourAccount")}
        </p>
      </div>

      <form className="md:col-span-2" onSubmit={handlePwdChange}>
        <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
          <div className="col-span-full">
            <CustomInputComponent
              name="currentpassword"
              type="password"
              placeholder={t("enterCurrentPassword")}
              required={true}
              onChange={onChangePwd}
              label={
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {t("currentPassword")}
                </label>
              }
              icon={
                <LockClosedIcon
                  className="block h-5 w-5 font-bold focus:text-form-color"
                  aria-hidden="true"
                />
              }
            />
          </div>

          <div className="col-span-full">
            <CustomInputComponent
              name="newpassword"
              type="password"
              placeholder={t("enterNewPassword")}
              required={true}
              onChange={onChangePwd}
              label={
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {t("newPassword")}
                </label>
              }
              icon={
                <LockClosedIcon
                  className="block h-5 w-5 font-bold focus:text-form-color"
                  aria-hidden="true"
                />
              }
            />
          </div>

          <div className="col-span-full">
            <CustomInputComponent
              name="confirmpassword"
              type="password"
              placeholder={t("confirmNewPassword")}
              required={true}
              onChange={onChangePwd}
              label={
                <label
                  htmlFor="password"
                  className="block text-sm font-medium leading-6 text-gray-900 dark:text-white"
                >
                  {t("confirmNewPassword")}
                </label>
              }
              icon={
                <LockClosedIcon
                  className="block h-5 w-5 font-bold focus:text-form-color"
                  aria-hidden="true"
                />
              }
            />
          </div>
        </div>

        {isLoading ? (
          <SpinnerLoading />
        ) : (
          <div className="mt-8 flex justify-center md:justify-start">
            <CustomButton
              type="submit"
              className="rounded-md py-2 px-3 text-sm font-semibold shadow-sm"
            >
              {t("save")}
            </CustomButton>
          </div>
        )}
      </form>
    </div>
  );
};

export default PasswordComponent;
