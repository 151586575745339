// usePagination.js
import { useState, useCallback } from "react";

export const usePagination = (initialPage, initialRowsPerPage) => {
  const [page, setPage] = useState(initialPage);
  const [rowsPerPage, setRowsPerPage] = useState(initialRowsPerPage);

  const handlePage = useCallback((page) => {
    setPage(page);
  }, []);

  const handleRowsPerPage = useCallback((page) => {
    setRowsPerPage(page);
  }, []);

  return { page, rowsPerPage, handlePage, handleRowsPerPage };
};
