import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../helper/http-client";

export const fetchEvents = createAsyncThunk(
  "event/fetchEvents",
  async ({ page, rowsPerPage, sortObj, selectedFilterItems }, thunkAPI) => {
    try {
      const searchParams = new URLSearchParams();
      selectedFilterItems.forEach((item) => {
        if (item.label !== "User") {
          searchParams.append("eventType", item.value);
        } else {
          searchParams.append("userName", item.value);
        }
      });
      const res = await http.get(
        `/event/get?page=${page}&limit=${rowsPerPage}&sortDirection=${
          sortObj.sortDirection
        }&${searchParams.toString()}`
      );
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const postEvent = createAsyncThunk(
  "event/postEvent",
  async (event, thunkAPI) => {
    try {
      const res = await http.post("/event/post", event);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const initialState = {
  events: [],
  isLoading: false,
  error: null,
};

export const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchEvents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchEvents.fulfilled, (state, action) => {
        state.events = action.payload;
        state.isLoading = false;
        state.error = null;
      })
      .addCase(fetchEvents.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(postEvent.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postEvent.fulfilled, (state, action) => {
        state.isLoading = false;
        state.error = null;
      })
      .addCase(postEvent.rejected, (state, action) => {
        state.error = action.payload;
        state.isLoading = false;
      });
  },
});

export default eventSlice.reducer;
