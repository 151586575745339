import { useTranslation } from "react-i18next";

import Loading from "../../components/Loading";
import { CustomButton } from "../../components/CustomButton";
import SummaryStaticCard from "../../components/Profile/SummaryStaticCard";
import AchievementImages from "../../helper/images";
import { useClubUserSummary } from "../../hooks/useClubUserSummary";
import { setTotalAchievements } from "../../helper/profileHelper";

const ProfileUserSummay = () => {
  const { t } = useTranslation();
  const {
    isLoading,
    clubUser: user,
    cntAchievementsNo,
    summaryData,
    handleBack,
  } = useClubUserSummary();

  return (
    <div className="p-8">
      <div className="flex flex-col md:flex-row bg-white dark:bg-gray-800 dark:text-white p-4 mb-4 rounded-md">
        <div className="flex flex-col w-full lg:w-3/12 p-4">
          <div className="flex justify-center items-center p-2">
            {user?.avatar === "" || user?.avatar === null ? (
              <img
                src="https://www.f-cdn.com/assets/main/en/assets/unknown.png?image-optimizer=force&format=webply&width=336 1x"
                className="rounded-md w-44 h-44"
                alt="user-avatar"
              />
            ) : (
              <img
                src={user?.avatar}
                className="rounded-md w-44 h-44"
                alt="user-avatar"
              />
            )}
          </div>
          <div className="text-center font-bold uppercase text-2xl md:text-sm xl:text-xl">
            {user?.username}
          </div>
        </div>
        <div className="flex flex-col w-full lg:w-9/12 p-4">
          <div className="flex justify-end mb-4">
            <CustomButton className="py-2 px-4 rounded-md" onClick={handleBack}>
              {t("back")}
            </CustomButton>
          </div>
          {cntAchievementsNo >= 5 && (
            <div className="flex justify-center">
              <div className="w-1/2">
                <img
                  src={
                    AchievementImages.HUNTER[
                      `hunter_${setTotalAchievements(cntAchievementsNo)}`
                    ]
                  }
                  alt="total achievement"
                />
              </div>
            </div>
          )}
          <div className="mb-8">
            <h5 className="w-full min-h-60 dark:bg-black dark:text-white p-4 text-xl text-left border border-t-8 border-t-green-600 dark:border-yellow-600 dark:border-t-green-600 rounded-md cursor-pointer ">
              {user?.profile ? user?.profile : "Introduction does not exist."}
            </h5>
          </div>
          <div className="dark:bg-black text-left text-md md:text-xl lg:text-2xl mb-8 p-4 border border-t-4 border-t-green-600 dark:border-yellow-600 dark:border-t-green-600 rounded-md">
            {t("lastOnline")}:{" "}
            <span className="font-bold">
              {new Date(user?.lastLoginDate).toLocaleString()}
            </span>
          </div>
          {isLoading ? <Loading /> : <SummaryStaticCard result={summaryData} />}
        </div>
      </div>
    </div>
  );
};

export default ProfileUserSummay;
