import { useMemo } from "react";
import { useLocation } from "react-router";
import { ArrowPathIcon, ArrowUturnLeftIcon } from "@heroicons/react/24/solid";
import ArenaReplyAvatar from "../../components/Arena/ArenaReplyAvatar";
import ArenaReplyDate from "../../components/Arena/ArenaReplyDate";
import ArenaElapseTime from "../../components/Arena/ArenaElapseTime";
import images from "../../helper/images";
import usePlayerAvatar from "../../hooks/Arena/usePlayerAvatar";
import useMatchEvents from "../../hooks/Arena/useMatchEvents";
import ArenaReplyProgress from "../../components/Arena/ArenaReplyProgress";

const ArenaReply = () => {
  const location = useLocation();
  const { match, isReply, message } = location.state || {};
  const { results, player1, player2, winner } = match;

  const player1Avatar = usePlayerAvatar(player1);
  const player2Avatar = usePlayerAvatar(player2);
  const {
    displayEvents: display,
    matchIndex,
    formatElapsedTime,
    player1Wins,
    player2Wins,
    playSpeed,
    currentPlayer1Score,
    currentPlayer2Score,
    isFinished,
    handleRestart,
    handlePlaySpeed,
  } = useMatchEvents(results, player1, player2, isReply, winner, message);

  const legNo = useMemo(() => {
    return matchIndex + 1 > results.length ? results.length : matchIndex + 1;
  }, [matchIndex, results.length]);

  const handleBack = () => {
    window.history.back();
  };

  return (
    <div
      className="relative flex flex-col w-full h-full bg-cover bg-center bg-no-repeat"
      style={{ backgroundImage: `url(${images?.ARENAREPLYBG})` }}
    >
      <div className="absolute w-full h-full top-0 left-0 dark:bg-[#171927bb] bg-[#0C0D15aa] z-10"></div>

      <div className="flex flex-col sm:flex-row items-center sm:items-start px-8 z-10 mt-24 gap-4">
        <h3 className="text-white text-6xl font-bebas-neue tracking-widest font-semibold uppercase">
          Leg <span className="text-green-500">{legNo}</span>
        </h3>

        <ArenaElapseTime elapsedTime={formatElapsedTime} />

        {isReply ? (
          <button
            className="border border-white bg-green-500 hover:border-green-700 rounded-md py-2 px-4 ml-0 sm:ml-auto text-white font-semibold"
            onClick={handleRestart}
          >
            <ArrowPathIcon className="w-6 h-6" />
          </button>
        ) : null}
        <button
          className={`flex items-center border border-green-500 hover:border-green-700 ${
            isReply ? "" : "ml-auto"
          } rounded-md px-4 text-white font-semibold`}
          onClick={handlePlaySpeed}
        >
          <span className="text-2xl mr-0.5 py-1">&times;</span>
          <span className="text-xl mt-1">{playSpeed}</span>
        </button>
        <button
          className="border border-white bg-green-500 hover:border-green-700 rounded-md py-2 px-4 mr-0 sm:mr-4 text-white font-semibold"
          onClick={handleBack}
        >
          <ArrowUturnLeftIcon className="w-6 h-6" />
        </button>
      </div>

      <div className="flex justify-center sm:justify-start mt-4 px-8 z-10">
        <ArenaReplyDate date={match.date} />
      </div>

      <div className="flex lg:hidden justify-center items-center flex-col sm:flex-row gap-4 p-4 mt-4">
        <div className="w-fit">
          <ArenaReplyAvatar
            player={player1}
            playerAvatar={player1Avatar}
            playerWins={player1Wins}
          />
        </div>
        <div className="w-fit">
          <ArenaReplyAvatar
            player={player2}
            playerAvatar={player2Avatar}
            playerWins={player2Wins}
          />
        </div>
      </div>

      <div className="flex items-end justify-center gap-12 p-8 z-20">
        <div className="hidden lg:block">
          <ArenaReplyAvatar
            player={player1}
            playerAvatar={player1Avatar}
            playerWins={player1Wins}
          />
        </div>

        <div className="relative">
          <div className="absolute w-full h-full top-0 left-0 dark:bg-[#171927bb] bg-[#0C0D15aa] z-10"></div>
          <div className="absolute -left-4 h-full z-20">
            <ArenaReplyProgress
              totalDisplay={501}
              display={currentPlayer1Score}
            />
          </div>
          <div className="relative h-96 w-80 py-4 flex flex-col gap-2 z-30 rounded-md border border-yellow-600 px-4">
            {isFinished ? (
              <div className="flex flex-col items-center justify-center p-4 bg-gray-100 rounded-lg shadow-md">
                <p className="text-2xl font-semibold text-gray-700">Winner</p>
                <p className="text-4xl font-bold font-bebas-neue tracking-widest text-center text-green-500 uppercase mt-2">
                  {winner}
                </p>
              </div>
            ) : (
              display?.map((event, index) => (
                <p
                  key={index}
                  className={`text-white text-left py-2 px-4 font-poppins border border-gray-200 rounded-md bg-green-500`}
                >
                  {event}
                </p>
              ))
            )}
          </div>
          <div className="absolute top-0 -right-4 h-full z-20">
            <ArenaReplyProgress
              totalDisplay={501}
              isMarkLeft={true}
              display={currentPlayer2Score}
            />
          </div>
        </div>

        <div className="hidden lg:block">
          <ArenaReplyAvatar
            player={player2}
            playerAvatar={player2Avatar}
            playerWins={player2Wins}
          />
        </div>
      </div>
    </div>
  );
};

export default ArenaReply;
