import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import http from "../helper/http-client";
import {
  fetchSeasonsStart,
  fetchSeasonsFailure,
  fetchSeasonsSuccess,
} from "../app/seasonSlice";
import { fetchGlobalCoins, fetchPrice } from "../app/priceSlice";

const useSeason = () => {
  const dispatch = useDispatch();
  const season = useSelector((state) => state.season);
  const { user } = useSelector((state) => state.auth);


  useEffect(() => {
    const fetchAllSeasons = async () => {
      dispatch(fetchSeasonsStart());
      try {
        const res = await http.get("/season/get-all");
        const resResult = await http.get("/result/fetch-all");

        const seasonData = res.data.map((item) => {
          const topMembers = resResult.data.filter((val) =>
            item.topMembers.includes(val._id)
          );
          return { ...item, topMembers };
        });

        dispatch(fetchSeasonsSuccess(seasonData));
        dispatch(fetchPrice({ userId: user?._id }));
        dispatch(fetchGlobalCoins());
      } catch (err) {
        dispatch(fetchSeasonsFailure(err));
      }
    };

    fetchAllSeasons();
  }, [dispatch]);

  return { season };
};

export default useSeason;
