import { useMemo } from "react";
import { useTranslation } from "react-i18next";

import images from "../../helper/images";
import AnimatedCounter from "../AnimatedCounter";
import { useCounter } from "../../hooks/useCounter";

const HomeCounter = () => {
  const { t } = useTranslation();
  const { LIFETIMECOUNTER } = images;
  const { seasonCount, lifetimeCount } = useCounter();

  const seasonCounter = useMemo(
    () => seasonCount.toString().split(""),
    [seasonCount]
  );
  const lifetimeCounter = useMemo(
    () => lifetimeCount.toString().split(""),
    [lifetimeCount]
  );

  console.log("season--counter-->>", seasonCount, lifetimeCount);

  return (
    <div className="relative flex flex-col items-center gap-4">
      <div className="relative">
        <img src={LIFETIMECOUNTER} alt="lifetime-counter" />
        <p className="absolute w-full text-white text-3xl font-bold top-4 left-1/2 -translate-x-1/2">
          {t("seasonFights")}
        </p>

        <div className="absolute w-full top-3/4 -translate-y-3/4 left-1/2 -translate-x-1/2 flex items-center justify-center gap-4">
          {seasonCounter.length &&
            seasonCounter.map((item, index) => (
              <p
                key={index}
                className="px-4 py-2 bg-black text-white text-5xl font-bold h-fit rounded-xl"
              >
                <AnimatedCounter targetValue={parseInt(item)} duration={1000} />
              </p>
            ))}
        </div>
      </div>
      <div className="relative">
        <img src={LIFETIMECOUNTER} alt="lifetime-counter" />
        <p className="absolute w-full text-white text-3xl font-bold top-4 left-1/2 -translate-x-1/2">
          {t("lifetimeFights")}
        </p>

        <div className="absolute w-full top-3/4 -translate-y-3/4 left-1/2 -translate-x-1/2 flex items-center justify-center gap-4">
          {lifetimeCounter.length &&
            lifetimeCounter.map((item, index) => (
              <p
                key={index}
                className="px-4 py-2 bg-black text-white text-5xl font-bold h-fit rounded-xl"
              >
                <AnimatedCounter targetValue={parseInt(item)} duration={1000} />
              </p>
            ))}
        </div>
      </div>
    </div>
  );
};

export default HomeCounter;
