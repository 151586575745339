import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";

import { useConstant } from "./useConstant";

export const useRankingFilter = () => {
  const { t } = useTranslation();
  const { rankingSortList } = useConstant();
  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState([
    {
      label: `${t("victoryStreak")}(${t("lifetime")})`,
      name: "streakLifetime",
      value: "streak",
      type: "lifetime",
      selected: false,
    },
  ]);
  const [checkedItems, setCheckedItems] = useState({
    streakLifetime: true,
    streakSeason: true,
    breakfastLifetime: true,
    breakfastSeason: true,
  });

  useEffect(() => {
    const newSelectedFilterItems = rankingSortList.filter(
      (item) => checkedItems[item.name]
    );

    if (
      JSON.stringify(newSelectedFilterItems) !==
      JSON.stringify(selectedFilterItems)
    ) {
      setSelectedFilterItems(newSelectedFilterItems);
    }
  }, [checkedItems, selectedFilterItems, rankingSortList]);

  const handleFilterMenu = useCallback(() => {
    setIsOpenFilterMenu((prev) => !prev);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpenFilterMenu(false);
  }, []);

  const handleFilterCheck = useCallback((e) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [e.target.value]: e.target.checked,
    }));
  }, []);

  return {
    isOpenFilterMenu,
    selectedFilterItems,
    checkedItems,
    rankingSortList,
    handleFilterMenu,
    closeDropdown,
    handleFilterCheck,
  };
};
