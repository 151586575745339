import PersonalComponent from "../components/Settings/PersonalComponent";
import PasswordComponent from "../components/Settings/PasswordComponent";

const Settings = () => {
  return (
    <div className="px-4 py-8 divide-y divide-white/5">
      <PersonalComponent />
      <PasswordComponent />
    </div>
  );
};

export default Settings;
