import React, { useMemo } from "react";
import CustomModal from "../CustomModal";
import AchievementImages from "../../helper/images";
import { convertStr } from "../../helper/helper";
import { useTranslation } from "react-i18next";

const Achievement = ({ achievement, prefix = "" }) => {
  const { name, index, value } = achievement;
  const imageName = name.toUpperCase().includes("SEASON")
    ? name.toUpperCase().split(".")[0].replace("SEASON", "")
    : name.toUpperCase().split(".")[0];
  const imageSrc = AchievementImages[imageName][index];

  return (
    <div className="grid grid-cols-5 gap-8 my-2 bg-white dark:bg-gray-800 text-balck dark:text-white">
      <div className="flex items-center">
        <img
          className="w-16 h-16"
          src={imageSrc}
          alt="achievement-icon"
          style={{ width: "100%", height: "auto" }}
        />
      </div>
      <p className="font-semibold text-xl col-span-4 flex items-center">
        {prefix} {convertStr(name.split(".")[0])} &nbsp; {value}
      </p>
    </div>
  );
};

const AchievementModal = ({ show, handleClose, updatedAchievements }) => {
  const { t } = useTranslation();
  const achievements = useMemo(() => {
    const regular = updatedAchievements?.filter(
      (achievement) =>
        !(
          achievement.name.includes("lifetime") ||
          achievement.name.includes("season")
        )
    );
    const lifetime = updatedAchievements?.filter((achievement) =>
      achievement.name.includes("lifetime")
    );
    const season = updatedAchievements?.filter((achievement) =>
      achievement.name.includes("season")
    );

    return { regular, lifetime, season };
  }, [updatedAchievements]);

  return (
    <CustomModal isOpen={show} onClose={handleClose} title="Achievements">
      <div className="mt-4">
        <p className="text-lg text-center text-gray-500">
          Wow, {t("youHaveEarned")} {updatedAchievements?.length}{" "}
          {t("achievements")}
        </p>
      </div>

      <div className="max-h-80 overflow-auto custom-scrollbar">
        {achievements.regular?.map((achievement, index) => (
          <Achievement key={index} achievement={achievement} />
        ))}
        {achievements.lifetime?.map((achievement, index) => (
          <Achievement
            key={index}
            achievement={achievement}
            prefix="Lifetime:"
          />
        ))}
        {achievements.season?.map((achievement, index) => (
          <Achievement key={index} achievement={achievement} prefix="SEASON:" />
        ))}
      </div>

      <div className="mt-4 flex justify-center gap-2">
        <button
          type="button"
          className="inline-flex justify-center rounded-md border border-transparent bg-green-600 px-4 py-2 text-sm font-medium text-white hover:bg-green-500 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
          onClick={handleClose}
        >
          {t("hooray")}
        </button>
      </div>
    </CustomModal>
  );
};

export default AchievementModal;
