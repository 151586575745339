import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSchedule } from "../app/scheduleSlice";
import { useNavigate } from "react-router-dom";

import emailNotify from "../helper/emailNotify";
import { updateAllUsers } from "../app/authSlice";
import { postEvent } from "../app/eventSlice";
import socket from "../socket";
import http from "../helper/http-client";

const addMinutes = (date, minutes) => {
  const dateCopy = new Date(date);
  dateCopy.setMinutes(dateCopy.getMinutes() + minutes);
  return dateCopy;
};

export const useSchedule = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const scheduleState = useSelector((state) => state.schedule);
  const userState = useSelector((state) => state.auth);
  const { schedules, isLoading } = scheduleState;
  const { user, allUsers } = userState;

  const [currentSchedule, setCurrentSchedule] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [isChallengeLoading, setIsChallengeLoading] = useState(false);

  useEffect(() => {
    dispatch(fetchSchedule());
  }, [dispatch]);

  const mySchedules = useMemo(
    () =>
      schedules?.filter(
        (val) =>
          val.challenger
            ?.toLowerCase()
            .includes(user?.username?.toLowerCase()) ||
          val.receiver?.toLowerCase().includes(user?.username?.toLowerCase())
      ),
    [schedules, user]
  );

  const events = useMemo(
    () =>
      mySchedules?.map((val) => ({
        id: val._id,
        title: `${val.challenger?.toLowerCase()} : ${val.receiver?.toLowerCase()}`,
        start: new Date(val.date),
        end: addMinutes(val.date, 60),
      })),
    [mySchedules]
  );

  const allEvents = useMemo(
    () =>
      schedules
        ?.map((val) => ({
          id: val._id,
          title: `${val?.challenger} : ${val?.receiver}`,
          start: new Date(val?.date),
          end: addMinutes(val.date, 60),
        }))
        .sort((a, b) => a.start - b.start),
    [schedules]
  );

  const handleCalendar = (e) => {
    setCurrentSchedule(schedules.find((val) => val._id === e.id));
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
  };

  const onClick = async () => {
    currentSchedule &&
      emailNotify.sendNotificationEmail(
        currentSchedule?.challenger,
        currentSchedule?.challengerEmail,
        currentSchedule?.receiver,
        currentSchedule?.receiverEmail,
        `${currentSchedule?.challenger} just created challenge. Please login https://lidarts.org and accept the challenge. Your username must be same with username of lidarts.org`,
        "Schedule Challenge"
      );
    // await http.post("/schedule/remove", currentSchedule);
    dispatch(fetchSchedule());

    setIsChallengeLoading(true);

    const occupiedUsers = allUsers.filter(
      (val) =>
        val.username.toLowerCase() ===
          currentSchedule?.receiver?.toLowerCase() ||
        val.username.toLowerCase() ===
          currentSchedule?.challenger?.toLowerCase()
    );

    if (occupiedUsers.length > 0) {
      occupiedUsers.forEach((val) => {
        dispatch(updateAllUsers({ ...val, status: "occupied" }));
      });
    }

    socket.emit("schedule-create-challenge", {
      message: `${currentSchedule?.challenger} has just created challenge. Please login https://lidarts.org and accept the challenge. Your username must be same with username of lidarts.org`,
      toId: allUsers?.find(
        (val) => val.username?.toLowerCase() === currentSchedule?.receiver?.toLowerCase()
      )?._id,
      fromId: allUsers?.find(
        (val) => val.username?.toLowerCase() === currentSchedule?.challenger?.toLowerCase()
      )?._id,
      to: currentSchedule?.receiver,
      from: currentSchedule?.challenger,
      token: currentSchedule?.token,
    });

    dispatch(
      postEvent({
        eventType: "schedule-token",
        user: currentSchedule?.challenger,
        targetUser: currentSchedule?.receiver,
        token: currentSchedule?.token,
      })
    );

    console.log("currentSchedule--->>>", currentSchedule);

    window.open(
      `https://lidarts.org/game/create?opponent_name=${currentSchedule.receiver}`,
      "_blank"
    );
    navigate(`/result/${currentSchedule.token}/schedule`);
  };

  const onDecline = async () => {
    try {
      await http.post("/schedule/remove", currentSchedule);
      await http.post("/event/post", {
        eventType: "decline",
        user: currentSchedule.receiver,
        targetUser: currentSchedule.challenger,
      });
      dispatch(fetchSchedule());
      onClose();
    } catch (err) {
      console.log("-schedule--err-->>>", err);
    }
  };

  const refreshSchedules = () => {
    dispatch(fetchSchedule());
  };

  return {
    user,
    events,
    allEvents,
    isLoading,
    isChallengeLoading,
    isOpen,
    currentSchedule,
    handleCalendar,
    refreshSchedules,
    onClose,
    onClick,
    onDecline,
  };
};
