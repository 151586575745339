import HomeSlider from "../components/Home/HomeSlider";
import HomeQuote from "../components/Home/HomeQuote";
import HomeScheduleSlider from "../components/Home/HomeScheduleSlider";
import HomeTopSection from "../components/Home/HomeTopSection";
import HomeCounter from "../components/Home/HomeCounter";

const Home = () => {
  return (
    <div className="flex flex-col pb-12">
      <div className="relative flex flex-col lg:flex-row">
        <div className="w-full lg:w-3/5 py-8 px-12">
          <div className="relative mb-16">
            <HomeSlider />
          </div>
          <div className="mb-8">
            <HomeQuote />
          </div>
          <div>
            <HomeTopSection />
          </div>
        </div>
        <div className="w-full lg:w-2/5 py-8 px-4">
          <div className="mb-4">
            <HomeCounter />
          </div>
          <div className="mb-4">
            <HomeScheduleSlider />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
