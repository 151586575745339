// useFiltering.js
import { useState, useEffect, useCallback } from "react";
import { useConstant } from "./useConstant";

export const useFiltering = (initialCheckedItems, initialFilterStr) => {
  const { eventFilterList } = useConstant();
  const [isOpenFilterMenu, setIsOpenFilterMenu] = useState(false);
  const [selectedFilterItems, setSelectedFilterItems] = useState([]);
  const [checkedItems, setCheckedItems] = useState(initialCheckedItems);
  const [filterStr, setFilterStr] = useState(initialFilterStr);

  useEffect(() => {
    const newSelectedFilterItems = eventFilterList
      .filter((item) => checkedItems[item.name])
      .map((item) =>
        item.name === "user" ? { ...item, value: filterStr.user } : item
      );

    if (
      JSON.stringify(newSelectedFilterItems) !==
      JSON.stringify(selectedFilterItems)
    ) {
      setSelectedFilterItems(newSelectedFilterItems);
    }
  }, [checkedItems, filterStr.user, selectedFilterItems, eventFilterList]);

  const handleFilter = useCallback(
    (e) =>
      setSelectedFilterItems((prev) => {
        const index = prev.findIndex((item) => item.name === "User");
        const updatedItems = [...prev];
        if (index !== -1) {
          updatedItems[index] = {
            ...updatedItems[index],
            value: filterStr.user,
          };
        } else {
          updatedItems.push({ name: "User", value: filterStr.user });
        }
        return updatedItems;
      }),
    [filterStr.user]
  );

  const handleFilterMenu = useCallback(() => {
    setIsOpenFilterMenu((prev) => !prev);
  }, []);

  const closeDropdown = useCallback(() => {
    setIsOpenFilterMenu(false);
  }, []);

  const handleFilterCheck = useCallback((e) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [e.target.value]: e.target.checked,
    }));
  }, []);

  const handleFilterInput = useCallback((e) => {
    setFilterStr(e);
  }, []);

  return {
    isOpenFilterMenu,
    selectedFilterItems,
    checkedItems,
    eventFilterList,
    handleFilter,
    handleFilterMenu,
    closeDropdown,
    handleFilterCheck,
    handleFilterInput,
  };
};
