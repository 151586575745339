import { Outlet } from "react-router";
import { Link, useLocation } from "react-router-dom";

import constant from "../helper/constant";
import { classNames } from "../helper/helper";

const InfoLayout = () => {
  const { useConstant } = constant;
  const { infoMenus } = useConstant();
  const location = useLocation();
  const { pathname } = location;
  return (
    <div className="flex flex-col items-center md:items-start md:flex-row md:divide-x">
      <div className="w-full p-4 md:p-8">
        <Outlet />
      </div>
    </div>
  );
};

export default InfoLayout;
