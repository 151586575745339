import {
  HomeIcon,
  AdjustmentsVerticalIcon,
  InformationCircleIcon,
  IdentificationIcon,
  CalendarDaysIcon,
  CalendarIcon,
  ExclamationCircleIcon,
  QuestionMarkCircleIcon,
  ChartBarIcon,
  TrophyIcon,
  RocketLaunchIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";

const quotes = [
  {
    origin: "Alles was du besitzt, besitzt irgendwann dich.",
    additive: "Alles was du nicht checkst, checkt irgendwann dich",
  },
  {
    origin:
      "Von dem Geld, das wir nicht haben, kaufen wir Dinge, die wir nicht brauchen, um Leuten zu imponieren, die wir nicht mögen.",
    additive:
      "Von dem Geld, das wir nicht haben, kaufen wir Dinge, die wir nicht brauchen, um ein Niveau zu erreichen, das niemandem imponiert.",
  },
  {
    origin:
      "Wir sind Konsumenten. Wir sind Abfallprodukte der allgemeinen Lifestyle-Obsessionen!",
    additive:
      "Wir sind Dartspieler. Wir sind Abfallprodukte der allgemeinen Leistungssport-Obsessionen!",
  },
  {
    origin: "Wenn Menschen denken, dass du stirbst, hören sie dir richtig zu.",
    additive:
      "Wenn Menschen denken, dass du checkst, passen sie erst richtig auf.",
  },
  {
    origin:
      "Zuerst musst du wissen, nicht fürchten, sondern wissen, dass du einmal sterben wirst.",
    additive:
      "Zuerst musst du wissen, nicht fürchten, wissen, dass du einmal das große Singlefeld verpassen wirst.",
  },
  {
    origin:
      "Erst nachdem wir alles verloren haben, haben wir die Freiheit, alles zu tun.",
    additive:
      "Erst nachdem wir das Spiel verloren haben, haben wir die Freiheit, alles zu checken",
  },
  {
    origin:
      "Kleine Benimmfrage: Wenn ich vorbei gehe… Wende ich Ihnen den Arsch oder den Schritt zu?",
    additive:
      "Kleine Benimmfrage: Wenn ich zum Handshake gehe, habe ich da meine Darts schon geholt oder stecken die noch?",
  },
  {
    origin: "Ich will, dass du mich schlägst, so hart wie du nur kannst!",
    additive: "Ich will, dass du scorest, so hart wie du nur kannst",
  },
  {
    origin:
      "Wir wurden durch das Fernsehen aufgezogen in dem Glauben, dass wir alle irgendwann mal Millionäre werden, Filmgötter, Rockstars.",
    additive:
      "Wir wurden durch das Fernsehen aufgezogen in dem Glauben, dass wir alle irgendwann mal Millionäre werden, Filmgötter, Rockstars.",
  },
  {
    origin:
      "Werden wir aber nicht! Und das wird uns langsam klar! Und wir sind kurz, ganz kurz vorm Ausrasten.",
    additive:
      "Werden wir aber nicht! Und das wird uns langsam klar! Und wir sind kurz, ganz kurz vorm Ausrasten.",
  },
  {
    origin:
      "Wir sind die Zweitgeborenen der Geschichte, Leute. Männer ohne Zweck, ohne Ziel.",
    additive:
      "Wir sind die Zweitgeborenen der Geschichte, Leute. Menschen ohne Zweck, ohne Ziel.",
  },
  {
    origin:
      "Wir haben keinen großen Krieg, keine große Depression. Unser großer Krieg ist kein spiritueller, unsere große Depression ist unser Leben.",
    additive:
      "Wir haben keinen großen Krieg, keine große Depression. Unser großer Krieg ist ein mentaler, unsere große Depression ist die doppel 1.",
  },
  {
    origin: "Du hast mich in einer seltsamen Phase meines Lebens getroffen…",
    additive:
      "Darts hat mich in einer seltsamen Phase meines Lebens getroffen…",
  },
  {
    origin: "Willkommen im Fight Club!",
    additive: "Willkommen im Darts Fight Club!",
  },
  {
    origin:
      "Die erste Regel des Fight Club lautet: Ihr verliert kein Wort über den Fight Club!",
    additive:
      "Die erste Regel des Fight DartsClub lautet: Ihr verliert ab und zu mal ein Wort über den Darts Fight Club.",
  },
  {
    origin:
      "Die zweite Regel des Fight Club lautet: Ihr verliert kein Wort über den Fight Club!",
    additive:
      "Die zweite Regel des Fight Club lautet: Ihr verliert ab und zu mal EIN WORT über den Darts Fight Club.",
  },
];

const useConstant = () => {
  const { t } = useTranslation();
  const infoMenus = [
    {
      name: t("rules"),
      to: "/infos/rules",
      current: "/rules",
      icon: <ExclamationCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("additive"),
      to: "/infos/additive",
      current: "/additive",
      icon: <InformationCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("tutorial"),
      to: "/infos/tutorial",
      current: "/tutorial",
      icon: <QuestionMarkCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
  ];

  const profileMenuItems = [
    {
      name: t("summary"),
      to: "/profile/summary",
      current: "summary",
      icon: <UserCircleIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("pyramidAchievements"),
      to: "/profile/pyramid",
      current: "pyramid",
      icon: <ChartBarIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("fightClubAchievements"),
      to: "/profile/participation",
      current: "/participation",
      icon: <RocketLaunchIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("personalAchievements"),
      to: "/profile/personal",
      current: "/personal",
      icon: <TrophyIcon className="w-6 h-6" aria-hidden="true" />,
    },
    {
      name: t("myCalendar"),
      to: "/profile/calendar",
      current: "/calendar",
      icon: <CalendarDaysIcon className="w-6 h-6" aria-hidden="true" />,
    },
  ];

  const eventFilterList = [
    { label: t("login"), value: "login" },
    { label: t("logout"), value: "logout" },
    { label: t("register"), value: "register" },
    { label: t("fight"), value: "match" },
    { label: t("schedule"), value: "schedule" },
    { label: t("quick"), value: "quick" },
    { label: t("user"), value: "" },
  ];

  return { infoMenus, eventFilterList, profileMenuItems };
};

const navigation = [
  {
    name: "Home",
    to: "/",
    current: "/home",
    icon: <HomeIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Pyramid",
    to: "/pyramid",
    current: "/pyramid",
    icon: <AdjustmentsVerticalIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Infos",
    to: "/infos",
    current: "/infos",
    icon: <InformationCircleIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Profile",
    to: "/profile-summary",
    current: "/profile",
    icon: <IdentificationIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Calendar",
    to: "/schedule",
    current: "/calendar",
    icon: <CalendarDaysIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Events",
    to: "/events",
    current: "/events",
    icon: <CalendarIcon className="h-4 w-4" aria-hidden="true" />,
  },
  {
    name: "Ranking",
    to: "/ranking-table",
    current: "/ranking",
    icon: <AdjustmentsVerticalIcon className="h-4 w-4" aria-hidden="true" />,
  },
];

const userMenuItems = [
  { name: "Your Profile", to: "/profile-summary" },
  { name: "Settings", to: "/settings" },
];

const useInfoTexts = () => {
  const { t } = useTranslation();
  const infoTexts = [
    {
      title: `${t("rule")}1`,
      text: t("rule1Text"),
    },
    {
      title: `${t("rule")}2`,
      text: t("rule2Text"),
    },
    {
      title: `${t("rule")}3`,
      text: t("rule3Text"),
    },
    {
      title: `${t("rule")}4`,
      text: t("rule4Text"),
    },
    {
      title: `${t("rule")}5`,
      text: t("rule5Text"),
    },
    {
      title: `${t("rule")}6`,
      text: t("rule6Text"),
    },
    {
      title: `${t("rule")}7`,
      text: t("rule7Text"),
    },
    {
      title: `${t("rule")}8`,
      text: t("rule8Text"),
    },
  ];

  const additiveTexts = [
    t("additiveText1"),
    t("additiveText2"),
    t("additiveText3"),
    t("additiveText4"),
  ];
  return { infoTexts, additiveTexts };
};

const languageList = [
  { label: "Deutsch", value: "de" },
  { label: "English", value: "en" },
];

const Animationcontainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.5,
      staggerChildren: 0.5,
    },
  },
};

const SubAnimationcontainer = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.7,
      staggerChildren: 0.5,
    },
  },
};

const TopDownAnimationitem = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

const RLAnimationitem = {
  hidden: { x: 20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const LRAnimationitem = {
  hidden: { x: -20, opacity: 0 },
  visible: {
    x: 0,
    opacity: 1,
  },
};

const paginationComponentOptions = {
  rowsPerPageText: "Rows per page",
  rangeSeparatorText: "of",
  selectAllRowsItem: true,
  selectAllRowsItemText: "All",
};

// const URL = "http://localhost:4000";
const URL = 'https://backend.dartsfightclub.de';

const constant = {
  quotes,
  navigation,
  userMenuItems,
  useInfoTexts,
  useConstant,
  Animationcontainer,
  SubAnimationcontainer,
  TopDownAnimationitem,
  RLAnimationitem,
  LRAnimationitem,
  languageList,
  paginationComponentOptions,
  URL,
};

export default constant;
