import { useState } from "react";
import { useTranslation } from "react-i18next";

import CustomModal from "./CustomModal";

const PriceModal = ({
  isOpenModal,
  challenger,
  receiver,
  onCloseModal,
  sendChallenge,
  sendQuickNotification,
}) => {
  const { t } = useTranslation();

  const [priceOption, setPriceOption] = useState("payAll");

  const handlePriceOptionChange = (e) => {
    setPriceOption(e.target.value);
  };

  const handlePriceOption = () => {
    sendQuickNotification(receiver, challenger, priceOption);
    sendChallenge();
    onCloseModal();
  };

  return (
    <CustomModal
      title={t("priceModalMsg")}
      isOpen={isOpenModal}
      onClose={onCloseModal}
    >
      <div className="bg-white dark:bg-gray-800 text-black dark:text-white pt-8">
        <div className="mb-4">
          <label className="flex items-center text-md">
            <input
              type="radio"
              name="paymentOption"
              value="payAll"
              checked={priceOption === "payAll"}
              onChange={handlePriceOptionChange}
              className="mr-2"
            />
            {t("payAll")}
          </label>
          <p className="text-xs pl-6 mt-2 opacity-80">
            {t("payAllDescription")}
          </p>
        </div>
        <div className="mb-4">
          <label className="flex items-center text-md">
            <input
              type="radio"
              name="paymentOption"
              value="payHalf"
              checked={priceOption === "payHalf"}
              onChange={handlePriceOptionChange}
              className="mr-2"
            />
            50 : 50
          </label>
          <p className="text-xs pl-6 mt-2 opacity-80">
            {t("50-50-description")}
          </p>
        </div>
        <div className="flex mt-4">
          <button
            className="bg-green-400 dark:bg-gray-900 text-white rounded-md py-2 px-4 hover:bg-gray-500 dark:hover:bg-gray-700 ml-auto"
            onClick={handlePriceOption}
          >
            {t("sendChallenge")}
          </button>
        </div>
      </div>
    </CustomModal>
  );
};

export default PriceModal;
