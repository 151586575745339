import { useState } from "react";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import http from "../helper/http-client";
import HandleResult from "../helper/result";
import socket from "../socket";
import { updateAllUsers } from "../app/authSlice";

export const useFightResult = (token, type) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state) => state.auth);
  const { user } = authData;

  const [isLoading, setIsLoading] = useState(false);
  const [lidartsUrl, setLidartsUrl] = useState("");
  const [fightResult, setFightResult] = useState({});
  const [earnedAchievement, setEarnedAchievement] = useState({
    user1: [],
    user2: [],
  });
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState(false);
  const [isSaveModalOpen, setIsSaveModalOpen] = useState(false);
  const [updateResult, setUpdateResult] = useState(null);
  const [currentEarned, setCurrentEarned] = useState(null);
  const [isSaved, setIsSaved] = useState(true);

  const fetchResultData = async () => {
    const url = lidartsUrl.split("/");
    url.splice(3, 0, "api");

    return await http.get("/result/get", {
      params: { url: url.join("/") },
      headers: { "Access-Control-Allow-Origin": "*" },
    });
  };

  const fetchAllResultData = async () => {
    setIsLoading(true);
    try {
      const res = await http.get("/result/fetch-all");
      return res?.data;
    } catch (err) {
      console.log("all-result-error-->>", err.data);
    } finally {
      setIsLoading(false);
    }
  };

  const getEarnedAchievement = (updatedResult, username, allData) => {
    const lowerCaseUsername = username?.toLowerCase();
    const userResult = updatedResult.find(
      (val) => val?.username?.toLowerCase() === lowerCaseUsername
    );
    const allUserResult = allData?.allResult?.find(
      (val) => val?.username?.toLowerCase() === lowerCaseUsername
    );

    let userIndex = 0;

    if (allData?.user1?.name?.toLowerCase() === lowerCaseUsername) {
      userIndex = 1;
    } else if (allData?.user2?.name?.toLowerCase() === lowerCaseUsername) {
      userIndex = 2;
    }

    return HandleResult.handleAchievement(userResult, allUserResult, allData, userIndex);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    try {
      const res = await fetchResultData();
      setFightResult(res.data);
      const updatedResult = HandleResult.updateResult(res.data);
      setUpdateResult(updatedResult);

      const earnedAchievement1 = getEarnedAchievement(
        updatedResult,
        res.data?.user1?.name,
        res.data
      );
      const earnedAchievement2 = getEarnedAchievement(
        updatedResult,
        res.data?.user2?.name,
        res.data
      );

      setEarnedAchievement({
        user1: earnedAchievement1,
        user2: earnedAchievement2,
      });

      if (
        user?.username?.toLowerCase() === res.data?.user1?.name?.toLowerCase()
      )
        setCurrentEarned(earnedAchievement1);
      else setCurrentEarned(earnedAchievement2);

      const results = await fetchAllResultData();

      const user1Init = results?.find(
        (val) => val?.username?.toLowerCase() === user?.username?.toLowerCase()
      );

      if (new Date(user1Init?.date) >= new Date(fightResult?.begin)) {
        toast(t("thisGameHasAlreadyBeenSaved"));
      } else {
        setIsSummaryModalOpen(true);
      }
    } catch (err) {
      console.log(err);
      toast.warning(err?.data);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();

    if (!token) {
      toast.warning(t("youAreNotAuthorizedToSaveTheGameResults"));
      return;
    }

    if (updateResult) {
      if (
        updateResult.find(
          (val) =>
            val?.username?.toLowerCase() === user?.username?.toLowerCase()
        )
      ) {
        const results = await fetchAllResultData();

        const user1Init = results?.find(
          (val) =>
            val?.username?.toLowerCase() === user?.username?.toLowerCase()
        );

        if (new Date(user1Init?.date) >= new Date(fightResult?.begin)) {
          toast(t("thisGameHasAlreadyBeenSaved"));
          setIsSaveModalOpen(true);
        } else {
          setIsLoading(true);

          try {
            await Promise.all(
              updateResult.map(async (val) => {
                try {
                  await http.post("/result/post", { data: val, token, type });
                  const toastId = "token-success";
                  if (!toast.isActive(toastId)) {
                    toast.success(t("gameResultsSavedSuccessfully"), {
                      toastId,
                    });
                  }
                  setIsSaveModalOpen(true);
                } catch (err) {
                  console.log(err);
                  const toastId = "token-error";
                  if (!toast.isActive(toastId)) {
                    toast.error(err.data, {
                      toastId,
                    });
                  }
                }
              })
            );
          } catch (err) {
            toast.error(t("anErrorOccurredWhileSavingTheData"));
          } finally {
            setIsLoading(false);
          }
        }
      } else {
        toast.warning(t("gameResultsCannotBeSaved"));
      }
    } else {
      toast.warning(t("gameResultsCannotBeSaved"));
    }
  };

  const onChange = (e) => {
    e.preventDefault();
    setLidartsUrl(e.target.value);
  };

  const onCloseSummaryMdal = () => {
    setIsSummaryModalOpen(false);
  };

  const updateUserStatus = () => {
    socket.emit("update-user-status", {
      status: "online",
      userID: user?._id,
    });

    dispatch(updateAllUsers({ ...user, status: "online" }));
  };

  const onCloseSaveModal = () => {
    setIsSaveModalOpen(false);

    updateUserStatus();

    setIsSaved(false);

    navigate("/pyramid-users");
  };

  return {
    isLoading,
    lidartsUrl,
    isSaved,
    fightResult,
    earnedAchievement,
    isSummaryModalOpen,
    isSaveModalOpen,
    currentEarned,
    onChange,
    updateUserStatus,
    onCloseSummaryMdal,
    onCloseSaveModal,
    handleSubmit,
    handleSave,
  };
};
