import { useCallback, useEffect } from "react";
import { useConfirmExit } from "./useConfirmExit";

export function usePrompt({ message, when = true, customFn }) {
  useEffect(() => {
    if (when) {
      window.onbeforeunload = function () {
        return message;
      };
    }

    return () => {
      window.onbeforeunload = null;
    };
  }, [message, when]);

  const confirmExit = useCallback(() => {
    const confirm = window.confirm(message);
    if (customFn && !!confirm) customFn();
    return confirm;
  }, [message]);
  useConfirmExit(confirmExit, when);
}
