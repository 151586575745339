import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../helper/http-client";

export const fetchPrice = createAsyncThunk(
  "price/fetchPrice",
  async ({ userId }, thunkAPI) => {
    try {
      const res = await http.get(`/auth/get-balance/${userId}`);
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updatePrice = createAsyncThunk(
  "price/updatePrice",
  async ({ userId, balance, type }, thunkAPI) => {
    try {
      const res = await http.post(`/auth/update-balance/${userId}`, {
        balance,
        type,
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const buyPrice = createAsyncThunk(
  "price/buyPrice",
  async ({ userId, price }, thunkAPI) => {
    try {
      const res = await http.post(`/auth/buy-balance/${userId}`, {
        balance: price,
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const fetchGlobalCoins = createAsyncThunk(
  "price/fetchGlobalCoins",
  async (thunkAPI) => {
    try {
      const res = await http.get("/auth/get-global-coin");
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

export const updateGlobalCoins = createAsyncThunk(
  "price/updateGlobalCoins",
  async (price, thunkAPI) => {
    console.log("updateGlobalCoins", price);
    try {
      const res = await http.post("/auth/update-global-coin", {
        balance: price,
      });
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const initialState = {
  customPrice: 0,
  globalCoins: 0,
  defaultPrice: 0,
  loading: false,
  error: null,
};

const priceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    resetPrice: (state) => {
      state.price = 0;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchPrice.fulfilled, (state, action) => {
        state.customPrice = action.payload?.customeBalance || 0;
        state.defaultPrice = action.payload?.defaultBalance || 6;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchPrice.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(buyPrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(buyPrice.fulfilled, (state, action) => {
        console.log("updatePrice.fulfilled", action.payload);
        state.customPrice = action.payload.value;
        state.loading = false;
        state.error = null;
      })
      .addCase(buyPrice.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(fetchGlobalCoins.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchGlobalCoins.fulfilled, (state, action) => {
        state.globalCoins = action.payload.amount;
        state.loading = false;
        state.error = null;
      })
      .addCase(fetchGlobalCoins.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updateGlobalCoins.pending, (state) => {
        state.loading = true;
      })
      .addCase(updateGlobalCoins.fulfilled, (state, action) => {
        state.globalCoins = action.payload.value;
        state.loading = false;
        state.error = null;
      })
      .addCase(updateGlobalCoins.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      })
      .addCase(updatePrice.pending, (state) => {
        state.loading = true;
      })
      .addCase(updatePrice.fulfilled, (state, action) => {
        const {type, value} = action.payload;
        if (type === 'custom') {
          state.customPrice = value;
        } else if (type === 'default') {
          state.defaultPrice = value;
        } 
        state.loading = false;
        state.error = null;
      })
      .addCase(updatePrice.rejected, (state, action) => {
        state.error = action.payload;
        state.loading = false;
      });
  },
});

export default priceSlice.reducer;
