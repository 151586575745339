import { useCallback } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper/modules";

import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router";

import useSeason from "../../hooks/useSeason";
import { updateAllUsers } from "../../app/authSlice";
import EmailNotify from "../../helper/emailNotify";
import socket from "../../socket";

const HomeTopSection = () => {
  const { season } = useSeason();
  const { user, allUsers } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentSeason } = season;

  const handleChallenge = useCallback(
    (player) => {
      socket.emit("top-challenge", {
        message: `${user.username} has sent you the challenge. The result of this challenge will not affect your ranking. It is just for fun. Enjoy!`,
        receiver: player.username,
        challenger: user.username,
      });

      const occupiedUsers = allUsers.filter(
        (val) =>
          val.username.toLowerCase() === player.username?.toLowerCase() ||
          val.username.toLowerCase() === user.username?.toLowerCase()
      );

      if (occupiedUsers.length > 0) {
        occupiedUsers.forEach((val) => {
          dispatch(updateAllUsers({ ...val, status: "occupied" }));
        });
      }

      window.open(
        `https://lidarts.org/game/create?opponent_name=${player?.username?.toLowerCase()}`,
        "_blank"
      );

      EmailNotify.sendNotificationEmail(
        user.username,
        user.email,
        player.username,
        player.email,
        `${user?.username} sent you a challenge. Please login https://lidarts.org and accept the challenge. Your username must be same with username of lidarts.org. The result of this challenge will not affect your ranking. It is just for fun. Enjoy!`,
        "Fun Challenge"
      );

      navigate("/top-challenge-result");
    },
    [user, navigate, allUsers, dispatch]
  );

  return (
    <Swiper
      pagination={{
        dynamicBullets: true,
      }}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      loop={true}
      grabCursor={true}
      modules={[Pagination, Autoplay]}
      className="mySwiper"
    >
      {currentSeason?.topMembers?.map((member, index) => (
        <SwiperSlide key={index}>
          <div className="relative flex bg-gradient-to-r h-[450px] sm:h-[350px] md:h-[250px] from-[#171927] to-[#0C0D15] p-6 rounded-lg">
            <p className="absolute top-0 w-full left-0 text-[80px] font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#242739] to-[#24273900]">
              DARTS CLUB
            </p>
            <div className="w-full md:w-2/3 text-white absolute px-6 left-0">
              <p className="text-4xl font-bold text-white mb-4 uppercase tracking-widest text-left">
                {member?.username}
              </p>
              <div className="flex z-10 gap-4">
                <div className="flex flex-wrap gap-2">
                  <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
                    <p className="text-sm">Fights</p>
                    <p className="text-3xl font-bold">
                      {member?.readyForIt.lifetime +
                        member?.friendlyChallenger.lifetime}
                    </p>
                  </div>
                  <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
                    <p className="text-sm">Victory Streak</p>
                    <p className="text-3xl font-bold">
                      {member?.maxVictoryStreak}
                    </p>
                  </div>
                  <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
                    <p className="text-sm">Master180</p>
                    <p className="text-3xl font-bold">
                      {member?.master26.lifetime}
                    </p>
                  </div>
                  <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
                    <p className="text-sm">Master26</p>
                    <p className="text-3xl font-bold">
                      {member?.master180.lifetime}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full md:w-1/3 absolute right-0 md:right-6 bottom-0 md:top-1/2 md:-translate-y-1/2 flex flex-row md:flex-col gap-4 items-center justify-center p-4 rounded-lg">
              <p>
                {member?.avatar ? (
                  <img
                    className="border border-[#26293B] bg-[#26293B] p-4 rounded-lg w-[120px] h-[120px]"
                    src={member?.avatar}
                    alt="user-avatar"
                  />
                ) : (
                  <p className="flex justify-center items-center border border-[#26293B] bg-[#26293B] p-4 rounded-lg w-[120px] h-[120px] text-5xl text-white font-bold">
                    {member?.username?.toUpperCase().charAt(0)}
                  </p>
                )}
              </p>
              <button
                className="py-2 px-4 border border-green-500 hover:border-green-600 text-white font-semibold rounded-md"
                disabled={
                  member?.username?.toLowerCase() ===
                  user?.username?.toLowerCase()
                }
                onClick={() => handleChallenge(member)}
              >
                Challenge
              </button>
            </div>
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
    // <Slider {...settings}>
    //   {currentSeason?.topMembers?.map((member, index) => (
    //     <div
    //       key={index}
    //       className="relative flex bg-gradient-to-r h-[450px] sm:h-[350px] md:h-[250px] from-[#171927] to-[#0C0D15] p-6 rounded-lg"
    //     >
    //       <p className="absolute top-0 w-full left-0 text-[80px] font-bold text-transparent bg-clip-text bg-gradient-to-b from-[#242739] to-[#24273900]">
    //         DARTS CLUB
    //       </p>
    //       <div className="w-full md:w-2/3 text-white absolute px-6 left-0">
    //         <p className="text-4xl font-bold text-white mb-4 uppercase tracking-widest text-left">
    //           {member?.username}
    //         </p>
    //         <div className="flex z-10 gap-4">
    //           <div className="flex flex-wrap gap-2">
    //             <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
    //               <p className="text-sm">Fights</p>
    //               <p className="text-3xl font-bold">
    //                 {member?.readyForIt.lifetime +
    //                   member?.friendlyChallenger.lifetime}
    //               </p>
    //             </div>
    //             <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
    //               <p className="text-sm">Victory Streak</p>
    //               <p className="text-3xl font-bold">
    //                 {member?.maxVictoryStreak}
    //               </p>
    //             </div>
    //             <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
    //               <p className="text-sm">Master180</p>
    //               <p className="text-3xl font-bold">
    //                 {member?.master26.lifetime}
    //               </p>
    //             </div>
    //             <div className="rounded-md border border-[#26293B] bg-[#26293B] px-4 py-2 h-fit">
    //               <p className="text-sm">Master26</p>
    //               <p className="text-3xl font-bold">
    //                 {member?.master180.lifetime}
    //               </p>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div className="w-full md:w-1/3 absolute right-0 md:right-6 bottom-0 md:top-1/2 md:-translate-y-1/2 flex flex-row md:flex-col gap-4 items-center justify-center p-4 rounded-lg">
    //         <p>
    //           {member?.avatar ? (
    //             <img
    //               className="border border-[#26293B] bg-[#26293B] p-4 rounded-lg w-[120px] h-[120px]"
    //               src={member?.avatar}
    //               alt="user-avatar"
    //             />
    //           ) : (
    //             <p className="flex justify-center items-center border border-[#26293B] bg-[#26293B] p-4 rounded-lg w-[120px] h-[120px] text-5xl text-white font-bold">
    //               {member?.username?.toUpperCase().charAt(0)}
    //             </p>
    //           )}
    //         </p>
    //         <button
    //           className="py-2 px-4 border border-green-500 hover:border-green-600 text-white font-semibold rounded-md"
    //           disabled={
    //             member?.username?.toLowerCase() ===
    //             user?.username?.toLowerCase()
    //           }
    //           onClick={() => handleChallenge(member)}
    //         >
    //           Challenge
    //         </button>
    //       </div>
    //     </div>
    //   ))}
    // </Slider>
  );
};

export default HomeTopSection;
