function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const isVariableEmpty = (value) => {
  if (value == null) {
    return true;
  }

  if (typeof value === "undefined") {
    return true;
  }

  if (!value) {
    return true;
  }

  if (value.length === 0) {
    return true;
  }

  if (typeof value === "object" && Object.keys(value).length === 0) {
    return true;
  }

  return false;
};

const getRandomInt = (min, max) => {
  const minCeiled = Math.ceil(min);
  const maxFloored = Math.floor(max);
  return Math.floor(Math.random() * (maxFloored - minCeiled) + minCeiled); // The maximum is exclusive and the minimum is inclusive
};

const convertAchievementName = (str) => {
  if (
    str.includes("master") ||
    str.includes("maestro") ||
    str.includes("protector") ||
    str.includes("conqueror") ||
    str.includes("climber") ||
    str.includes("grand")
  ) {
    return str
      .split(/(?=[A-Z])/)
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  } else {
    return str.replace(/([A-Z])/g, " $1").toUpperCase();
  }
};

const getCurrentTimeZone = () => {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
};

const transformTableData = (data) => {
  return data
    .map((val, index) => ({
      id: val._id,
      ranking: val.level,
      avatar: val.avatar,
      name: val.username,
      breakfast: val.master26,
      streak: {
        lifetime: val.maxVictoryStreak,
        season: val.seasonMaxVictoryStreak,
      },
      friendly: val.friendlyChallenger,
      ready: val.readyForIt,
      dart: val.dartEnthusiast,
      consistentScorer: val.consistentScorer,
      ironDart: val.ironDart,
      monthlyMaestro: val.monthlyMaestro,
      pyramidProtector: val.pyramidProtector,
      grandMaster: val.grandMaster,
      challengeConqueror: val.challengeConqueror,
      pyramidClimber: val.pyramidClimber,
      master180: val.master180,
    }))
    .sort((a, b) => b.ranking - a.ranking);
};

const convertStr = (str) => {
  return str.replace(/([A-Z])/g, " $1").toUpperCase();
};

export {
  classNames,
  isVariableEmpty,
  getRandomInt,
  convertAchievementName,
  getCurrentTimeZone,
  transformTableData,
  convertStr,
};
