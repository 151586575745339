import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "../helper/http-client";

export const fetchSchedule = createAsyncThunk(
  "schedule/fetchSchedule",
  async (thunkAPI) => {
    try {
      const res = await http.get("/schedule/fetch-all");
      return res.data;
    } catch (err) {
      return thunkAPI.rejectWithValue(err?.data);
    }
  }
);

const initialState = {
  schedules: [],
  isLoading: false,
  error: null,
};

export const scheduleSlice = createSlice({
  name: "schedule",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchSchedule.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(fetchSchedule.fulfilled, (state, action) => {
        state.isLoading = false;
        state.schedules = action.payload;
      })
      .addCase(fetchSchedule.rejected, (state, action) => {
        state.isLoading = false;
        state.error = action.payload;
      });
  },
});

export default scheduleSlice.reducer;
