const getHighMarks = () => {
  const highMarks = [170, 167, 164, 161, 160, 158];
  for (let i = 157; i > 100; i--) {
    highMarks.push(i);
  }
  return highMarks;
};

const updateStreaks = (user, opponent) => {
  const won = user.won > opponent.won;
  const previousWin = user.init.previousWin === true;
  return {
    currentVictoryStreak: won
      ? previousWin
        ? user.init.currentVictoryStreak + 1
        : 1
      : 0,
    seasonCurrentVictoryStreak: won
      ? previousWin
        ? user.init.seasonCurrentVictoryStreak + 1
        : 1
      : 0,
  };
};

const updateWinsAndLevel = (user, opponent, rowSpotNo) => {
  const won = user.won > opponent.won;
  const level =
    user.init.level > opponent.init.level
      ? won
        ? user.init.level
        : opponent.init.level
      : user.init.level < opponent.init.level
      ? won
        ? opponent.init.level
        : user.init.level
      : won
      ? rowSpotNo < 1
        ? user.init.level
        : user.init.level + 1
      : user.init.level;
  return {
    totalWinNo: won ? user.init.totalWinNo + 1 : user.init.totalWinNo,
    level,
  };
};

const updatePyramidClimber = (user, updatedUser) => {
  return {
    pyramidClimber: {
      lifetime:
        user.init.level < updatedUser.level
          ? user.init.pyramidClimber.lifetime + 1
          : user.init.pyramidClimber.lifetime,
      season:
        user.init.level < updatedUser.level
          ? user.init.pyramidClimber.season + 1
          : user.init.pyramidClimber.season,
    },
  };
};

const updatePyramidProtector = (user, opponent) => {
  const won = user.won > opponent.won;
  return {
    pyramidProtector: {
      lifetime:
        user.init.level > opponent.init.level && won
          ? user.init.pyramidProtector.lifetime + 1
          : user.init.pyramidProtector.lifetime,
      season:
        user.init.level > opponent.init.level && won
          ? user.init.pyramidProtector.season + 1
          : user.init.pyramidProtector.season,
    },
  };
};

const updateLegendaryRivalry = (user, opponent) => {
  return {
    legendaryRivalry: user.init.legendaryRivalry.find(
      (val) => val.opponent === opponent.init.username?.toLowerCase()
    )
      ? user.init.legendaryRivalry.map((val) =>
          val.opponent === opponent.init.username?.toLowerCase()
            ? { ...val, lifetime: val.lifetime + 1, season: val.season + 1 }
            : { ...val }
        )
      : user.init.legendaryRivalry.concat({
          opponent: opponent.init.username?.toLowerCase(),
          lifetime: 1,
          season: 1,
        }),
  };
};

const updateIronDart = (user, opponent, matchResult) => {
  const won = user.won > opponent.won;
  const doubles =
    user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
      ? Number(matchResult.p1_doubles)
      : Number(matchResult.p2_doubles);
  return {
    ironDart: {
      lifetime: won
        ? user.init.ironDart.lifetime < doubles
          ? doubles
          : user.init.ironDart.lifetime
        : user.init.ironDart.lifetime,
      season: won
        ? user.init.ironDart.season < doubles
          ? doubles
          : user.init.ironDart.season
        : user.init.ironDart.season,
    },
  };
};

const updateMaster180 = (user, matchResult) => {
  if (user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()) {
    return {
      master180: {
        lifetime: matchResult.hasOwnProperty("p1_180")
          ? user.init.master180.lifetime + matchResult?.p1_180
          : user.init.master180.lifetime,
        season: matchResult.hasOwnProperty("p1_180")
          ? user.init.master180.season + matchResult?.p1_180
          : user.init.master180.season,
      },
    };
  } else {
    return {
      master180: {
        lifetime: matchResult.hasOwnProperty("p2_180")
          ? user.init.master180.lifetime + matchResult?.p2_180
          : user.init.master180.lifetime,
        season: matchResult.hasOwnProperty("p2_180")
          ? user.init.master180.season + matchResult?.p2_180
          : user.init.master180.season,
      },
    };
  }
};

const updateGrandMaster = (user, matchResult, result) => {
  const matchAvg =
    user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
      ? Number(matchResult?.p1_match_avg)
      : Number(matchResult?.p2_match_avg);

  let matchNo = Object.keys(result);
  let legs = [];

  if (user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()) {
    matchNo.map((val) => {
      if (result[val][1]?.scores.length === 0) return false;

      let sum = result[val][1]?.scores?.reduce((acc, sub) => acc + sub, 0);

      legs.push(sum / result[val][1]?.scores?.length);
    });
  } else {
    matchNo.map((val) => {
      if (result[val][2]?.scores.length === 0) return false;

      let sum = result[val][2]?.scores?.reduce((acc, sub) => acc + sub, 0);

      legs.push(sum / result[val][2]?.scores?.length);
    });
  }

  return {
    grandMaster: {
      lifetime: {
        leg:
          user.init.grandMaster?.lifetime?.leg > Math.max(...legs)
            ? user.init.grandMaster?.lifetime?.leg
            : Math.max(...legs),
        match:
          user.init.grandMaster?.lifetime?.match > matchAvg
            ? user.init.grandMaster?.lifetime?.match
            : matchAvg,
      },
      season: {
        leg:
          user.init.grandMaster?.season?.leg > Math.max(...legs)
            ? user.init.grandMaster?.season?.leg
            : Math.max(...legs),
        match:
          user.init.grandMaster?.season?.match > matchAvg
            ? user.init.grandMaster?.season?.match
            : matchAvg,
      },
    },
  };
};

const updateMaxMarksman = (user, matchResult) => {
  return {
    maxMarksman:
      user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
        ? matchResult.hasOwnProperty("p1_171")
        : matchResult.hasOwnProperty("p2_171"),
  };
};

const updateDartEnthusiast = (user) => {
  console.log(
    "---dart--enthusiast--->>>",
    user.init.dartEnthusiast.lifetime,
    user.init.dartEnthusiast.season
  );
  return {
    dartEnthusiast: {
      lifetime: Number(user.init.dartEnthusiast.lifetime) + 1,
      season: Number(user.init.dartEnthusiast.season) + 1,
    },
  };
};

const updateLink = (matchResult) => {
  return {
    link: `https://lidarts.org/game/${matchResult?.hashid}`,
  };
};

const updateTargetUser = (user, matchResult) => {
  return {
    targetUser:
      user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
        ? matchResult?.p2_name?.toLowerCase()
        : matchResult?.p1_name?.toLowerCase(),
  };
};

const updateWon = (user, opponent) => {
  return {
    won: user.won,
    targetWon: opponent.won,
  };
};

const updateMaxvictoryStreak = (user, userUpdate) => {
  return {
    maxVictoryStreak:
      userUpdate.currentVictoryStreak > user.init.maxVictoryStreak
        ? userUpdate.currentVictoryStreak
        : user.init.maxVictoryStreak,
    seasonMaxVictoryStreak:
      userUpdate.seasonCurrentVictoryStreak > user.init.seasonMaxVictoryStreak
        ? userUpdate.seasonCurrentVictoryStreak
        : user.init.seasonMaxVictoryStreak,
  };
};

const updateFriendlyChallenger = (user) => {
  return {
    friendlyChallenger: {
      lifetime: Number(user.init.friendlyChallenger.lifetime) + 1,
      season: Number(user.init.friendlyChallenger.season) + 1,
    },
  };
};

const updateMonthlyMaestro = (user, userUpdate) => {
  return {
    monthlyMaestro: {
      lifetime:
        userUpdate.level === 6
          ? user.init.monthlyMaestro.lifetime + 1
          : user.init.monthlyMaestro.lifetime,
      season:
        userUpdate.level === 6
          ? user.init.monthlyMaestro.season + 1
          : user.init.monthlyMaestro.season,
    },
  };
};

const updateChampionChallenger = (opponent) => {
  return {
    championChallenger: opponent.init.level === 6 ? true : false,
  };
};

const updateReadyForIt = (user) => {
  return {
    readyForIt: {
      lifetime: Number(user.init.readyForIt.lifetime) + 1,
      season: Number(user.init.readyForIt.season) + 1,
    },
  };
};

const updateChallengeConqueror = (user, opponent) => {
  return {
    challengeConqueror: {
      lifetime:
        user.won > opponent.won
          ? Number(user.init.challengeConqueror.lifetime) + 1
          : user.init.challengeConqueror.lifetime,
      season:
        user.won > opponent.won
          ? Number(user.init.challengeConqueror.season) + 1
          : user.init.challengeConqueror.season,
    },
  };
};

const updateSummary = (user, userUpdate, matchResult) => {
  return {
    summary: user.init.summary.concat({
      doubles:
        user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
          ? matchResult?.p1_doubles
          : matchResult?.p2_doubles,
      master180:
        user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
          ? matchResult?.p1_180
          : matchResult?.p2_180,
      first9Avg:
        user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
          ? matchResult?.p1_first9_avg
          : matchResult?.p2_first9_avg,
      matchAvg:
        user?.name?.toLowerCase() === matchResult.p1_name?.toLowerCase()
          ? matchResult?.p1_match_avg
          : matchResult?.p2_match_avg,
      level: userUpdate.level,
      date: new Date(matchResult.begin),
    }),
  };
};

const updateScores = (userUpdate, opponentUpdate, result) => {
  let cntBreakfast1 = 0;
  let cntBreakfast2 = 0;
  let cntConsistentScorer1 = 0;
  let cntConsistentScorer2 = 0;

  let user1 = userUpdate;
  let user2 = opponentUpdate;

  Object.values(result).map((val) => {
    if (val[1].hasOwnProperty("to_finish")) {
      const high = getHighMarks().findIndex(
        (mark) => mark === val[1].scores[val[1].scores.length - 1]
      );
      if (high >= 0) {
        user1 = {
          ...userUpdate,
          highFinish: userUpdate.highFinish.map((item, index) =>
            index === high ? 1 : item
          ),
        };
      }
    }
    if (val[2].hasOwnProperty("to_finish")) {
      const high = getHighMarks().findIndex(
        (mark) => mark === val[2].scores[val[2].scores.length - 1]
      );
      if (high >= 0) {
        user2 = {
          ...opponentUpdate,
          highFinish: opponentUpdate.highFinish.map((item, index) =>
            index === high ? 1 : item
          ),
        };
      }
    }

    console.log("val===>>>>", val[1].scores, val[2].scores);

    cntBreakfast1 += val[1].scores.filter((score) => score === 26).length;
    cntBreakfast2 += val[2].scores.filter((score) => score === 26).length;

    val[1].scores.map((item) => {
      if (Number(item) > 100) cntConsistentScorer1++;
      return item;
    });
    val[2].scores.map((item) => {
      if (Number(item) > 100) cntConsistentScorer2++;
      return item;
    });
  });

  user1 = {
    ...user1,
    master26: {
      lifetime: user1.master26.lifetime + cntBreakfast1,
      season: user1.master26.season + cntBreakfast1,
    },
    consistentScorer: {
      lifetime: user1.consistentScorer.lifetime + cntConsistentScorer1,
      season: user1.consistentScorer.season + cntConsistentScorer1,
    },
  };
  user2 = {
    ...user2,
    master26: {
      lifetime: user2.master26.lifetime + cntBreakfast2,
      season: user2.master26.season + cntBreakfast2,
    },
    consistentScorer: {
      lifetime: user2.consistentScorer.lifetime + cntConsistentScorer2,
      season: user2.consistentScorer.season + cntConsistentScorer2,
    },
  };

  return { user1, user2 };
};

const updateResult = (data) => {
  console.log("-->>data>>>", data);
  const { user1, user2, matchResult, result } = data;
  const availablePositionNo = Math.pow(2, 7 - user1.init.level);
  const currentAbovePlayersNo = data.allResult.filter(
    (val) => val.level === user1.init.level + 1
  ).length;
  const rowSpotNo = availablePositionNo - currentAbovePlayersNo;

  let user1Update = {
    ...user1.init,
    ...updateStreaks(user1, user2),
    ...updateChampionChallenger(user2),
    ...updateWinsAndLevel(user1, user2, rowSpotNo),
    ...updatePyramidProtector(user1, user2),
    ...updateLegendaryRivalry(user1, user2),
    ...updateIronDart(user1, user2, matchResult),
    ...updateMaster180(user1, matchResult),
    ...updateGrandMaster(user1, matchResult, result),
    ...updateMaxMarksman(user1, matchResult),
    ...updateDartEnthusiast(user1),
    ...updateLink(matchResult),
    ...updateTargetUser(user1, matchResult),
    ...updateWon(user1, user2),
    date: new Date(matchResult.begin),
  };

  let user2Update = {
    ...user2.init,
    ...updateStreaks(user2, user1),
    ...updateChampionChallenger(user1),
    ...updateWinsAndLevel(user2, user1, rowSpotNo),
    ...updatePyramidProtector(user2, user1),
    ...updateLegendaryRivalry(user2, user1),
    ...updateIronDart(user2, user1, matchResult),
    ...updateMaster180(user2, matchResult),
    ...updateGrandMaster(user2, matchResult, result),
    ...updateMaxMarksman(user2, matchResult),
    ...updateDartEnthusiast(user2),
    ...updateLink(matchResult),
    ...updateTargetUser(user2, matchResult),
    ...updateWon(user2, user1),
    ...updateChallengeConqueror(user2, user1),
    date: new Date(matchResult.begin),
  };

  const { user1: user1Scores, user2: user2Scores } = updateScores(
    user1Update,
    user2Update,
    result
  );

  const updateUser1Summary = updateSummary(user1, user1Update, matchResult);

  user1Update = {
    ...user1Update,
    ...user1Scores,
  };

  user2Update = {
    ...user2Update,
    ...user2Scores,
  };

  user1Update = {
    ...user1Update,
    ...updateMaxvictoryStreak(user1, user1Update),
    ...updateFriendlyChallenger(user1),
    ...updateMonthlyMaestro(user1, user1Update),
    ...updatePyramidClimber(user1, user1Update),
    ...updateUser1Summary,
    previousWin: user1.won > user2.won,
  };

  console.log("summary-->>", updateUser1Summary, "-->>", {
    ...user1Update,
    ...updateMaxvictoryStreak(user1, user1Update),
    ...updateFriendlyChallenger(user1),
    ...updateMonthlyMaestro(user1, user1Update),
    ...updatePyramidClimber(user1, user1Update),
    ...updateUser1Summary,
    previousWin: user1.won > user2.won,
  });

  user2Update = {
    ...user2Update,
    ...updateMaxvictoryStreak(user2, user2Update),
    ...updateReadyForIt(user2),
    ...updateMonthlyMaestro(user2, user2Update),
    ...updatePyramidClimber(user2, user2Update),
    ...updateSummary(user2, user2Update, matchResult),
    previousWin: user2.won > user1.won,
  };

  user1Update = {
    ...user1Update,
    earnedAchievements: handleAchievement(user1Update, data.user1.init, data, 1),
  };
  user2Update = {
    ...user2Update,
    earnedAchievements: handleAchievement(user2Update, data.user2.init, data, 2),
  };

  console.log("user1Update-->>", user1Update, "user2Update-->>", user2Update);

  return [user1Update, user2Update];
};

function findModifiedProperties(obj1, obj2) {
  const modifiedProperties = {};

  for (let key in obj1) {
    if (key === "date") continue;
    if (typeof obj1[key] === "object" && typeof obj2[key] === "object") {
      const nestedModifiedProperties = findModifiedProperties(
        obj1[key],
        obj2[key]
      );
      if (Object.keys(nestedModifiedProperties).length > 0) {
        modifiedProperties[key] = nestedModifiedProperties;
      }
    } else if (obj1[key] !== obj2[key]) {
      modifiedProperties[key] = {
        new: obj1[key],
        old: obj2[key],
      };
    }
  }

  return modifiedProperties;
}

const getMilestones = (achievemenName) => {
  const milestonesMap = {
    pyramidClimber: [1, 3, 5, 10, 20],
    challengeConqueror: [3, 5, 10, 20, 50],
    maxVictoryStreak: [1, 2, 4, 6, 8, 10],
    seasonMaxVictoryStreak: [1, 2, 4, 6, 8, 10],
    monthlyMaestro: [1, 2, 3, 4, 5, 6, 7, 8, 9],
    pyramidProtector: [1, 3, 5, 10, 20],
    legendaryRivalry: [1, 5, 10, 20, 50, 100],
    ironDart: [10, 20, 30, 40, 50, 60, 70, 80, 90, 100],
    master180: [1, 3, 5, 10, 20, 100],
    consistentScorer: [1, 5, 10, 20, 50],
    breakfast: [10, 30, 50, 100, 200, 300, 400, 750, 1000],
    dartEnthusiast: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    friendlyChallenger: [1, 5, 10, 20, 50, 100],
    readyForIt: [1, 3, 5, 10, 25, 30, 35, 50],
  };

  for (let key in milestonesMap) {
    if (achievemenName.includes(key)) {
      return milestonesMap[key];
    }
  }

  return [];
};

function checkMilestones(achievementName, achievement, milestones) {
  const newAchievements = [];
  for (let i = 0; i < milestones.length; i++) {
    let milestone = milestones[i];
    if (achievement.new >= milestone && achievement.old < milestone) {
      newAchievements.push({
        name: achievementName,
        value: milestone,
        index: i,
      });
    }
  }
  return newAchievements;
}

function handleNewAchievementCheck(achievementName, achievement) {
  const milestones = getMilestones(achievementName);

  if (achievement.hasOwnProperty("new") && achievement.hasOwnProperty("old")) {
    return checkMilestones(achievementName, achievement, milestones);
  } else {
    const newAchievements = [];
    for (let key in achievement) {
      if (achievement.hasOwnProperty(key)) {
        let nestedAchievement = achievement[key];
        if (
          (nestedAchievement.new !== undefined &&
            nestedAchievement.old !== undefined) ||
          (nestedAchievement.hasOwnProperty("lifetime") &&
            nestedAchievement.hasOwnProperty("season"))
        ) {
          newAchievements.push(
            ...handleNewAchievementCheck(
              achievementName + "." + key,
              nestedAchievement
            )
          );
        }
      }
    }
    return newAchievements;
  }
}

const handleAchievement = (data, origin, allData, userIndex) => {
  const achievementData = findModifiedProperties(data, origin);
  let newAchievements = [];
  for (var achievementName in achievementData) {
    if (achievementData.hasOwnProperty(achievementName)) {
      var achievement = achievementData[achievementName];
      newAchievements.push(
        ...handleNewAchievementCheck(achievementName, achievement)
      );
    }
  }

  Object.values(allData?.result).forEach((item) => {
    if (item[userIndex].hasOwnProperty('to_finish')) {
      const high = getHighMarks().findIndex(
        (mark) => mark === item[userIndex].scores[item[userIndex].scores.length - 1]
      );

      if (high !== -1) {
        newAchievements.push({
          index: high,
          name: 'finishingAce',
          value: item[userIndex].scores[item[userIndex].scores.length - 1],
        });
      }
    }
  })
  console.log("Achievement earned: ", newAchievements, '--->>', allData);
  return newAchievements;
};

const topBriefResult = (result) => [
  {
    title: "Avg",
    data1: result?.user1?.avg,
    data2: result?.user2?.avg,
  },
  {
    title: "First 9 Avg",
    data1: result?.matchResult?.p1_first9_avg,
    data2: result?.matchResult?.p2_first9_avg,
  },
  {
    title: "Doubles",
    data1: result?.matchResult?.p1_doubles,
    data2: result?.matchResult?.p2_doubles,
  },
  {
    title: "Highest Finish",
    data1: result?.matchResult?.p1_high_finish,
    data2: result?.matchResult?.p2_high_finish,
  },
  {
    title: "180",
    data1: result?.matchResult?.p1_180,
    data2: result?.matchResult?.p2_180,
  },
  {
    title: "171",
    data1: result?.matchResult?.p1_171,
    data2: result?.matchResult?.p2_171,
  },
  {
    title: "140",
    data1: result?.matchResult?.p1_140,
    data2: result?.matchResult?.p2_140,
  },
  {
    title: "100",
    data1: result?.matchResult?.p1_100,
    data2: result?.matchResult?.p2_100,
  },
  {
    title: "80",
    data1: result?.matchResult?.p1_80,
    data2: result?.matchResult?.p2_80,
  },
  {
    title: "60",
    data1: result?.matchResult?.p1_60,
    data2: result?.matchResult?.p2_60,
  },
  {
    title: "40",
    data1: result?.matchResult?.p1_40,
    data2: result?.matchResult?.p2_40,
  },
  {
    title: "20",
    data1: result?.matchResult?.p1_40,
    data2: result?.matchResult?.p2_40,
  },
];

const handleResult = {
  updateResult,
  handleAchievement,
  topBriefResult,
};

export default handleResult;
