// useAuthForm.js
import { useState } from "react";

export const useForm = (initialState) => {
  const [formData, setFormData] = useState(initialState);

  const handleChange = (payload) => {
    setFormData({ ...formData, ...payload });
  };

  return [formData, handleChange];
};
