import { useState, useEffect, useRef } from "react";
import { Outlet } from "react-router";

import Header from "../components/Header";
import Sidebar from "../components/Sidebar";

const MainLayout = () => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);

  const onCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const onOpenSidebar = () => {
    setIsSidebarOpen(true);
  };

  const handleClickOutside = (event) => {
    event.stopPropagation();
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <div className="max-w-10xl h-100vh flex flex-col ml-auto mr-auto relative">
      <Header
        isOpenSidebar={isSidebarOpen}
        onOpenSidebar={onOpenSidebar}
        onCloseSidebar={onCloseSidebar}
      />
      <div className="flex flex-1 pt-24">
        <div
          ref={sidebarRef}
          className={`${
            isSidebarOpen ? "block" : "hidden"
          } w-60 fixed top-0 md:block z-40 bg-white h-full pt-24 overflow-hidden overflow-y-auto`}
        >
          <Sidebar onCloseSidebar={onCloseSidebar} />
        </div>
        <div className="flex-1 pl-0 md:pl-60 h-full mr-auto ml-auto overflow-hidden overflow-y-auto bg-[#E8E8E8] dark:bg-gray-800">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default MainLayout;
