import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  seasons: [],
  isLoading: false,
  error: null,
  currentSeason: {},
  lastSeason: {},
};

export const seasonSlice = createSlice({
  name: "season",
  initialState,
  reducers: {
    fetchSeasonsStart: (state) => {
      state.isLoading = true;
      state.error = null;
    },
    fetchSeasonsSuccess: (state, action) => {
      state.seasons = action.payload;
      state.currentSeason = action.payload?.sort(
        (b, a) => a?.season - b?.season
      )[0];
      state.lastSeason = action.payload?.sort(
        (b, a) => a?.season - b?.season
      )[1];
      state.isLoading = false;
    },
    fetchSeasonsFailure: (state, action) => {
      state.error = action.payload;
      state.isLoading = false;
    },
  },
});

export const { fetchSeasonsStart, fetchSeasonsSuccess, fetchSeasonsFailure } =
  seasonSlice.actions;

export default seasonSlice.reducer;
