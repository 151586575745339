import { useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import arenaXpSound from "../../assets/audio/xp-sound.MP3";

const useMatchEvents = (
  results,
  player1,
  player2,
  isReply,
  winner,
  message
) => {
  const { user } = useSelector((state) => state.auth);
  const [elpasedTime, setElapsedTime] = useState(0);
  const [currentEventIndex, setCurrentEventIndex] = useState(0);
  const [matchIndex, setMatchIndex] = useState(0);
  const [isLegFinished, setIsLegFinished] = useState(false);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [player1Reached301, setPlayer1Reached301] = useState(false);
  const [player2Reached301, setPlayer2Reached301] = useState(false);
  const [player1Wins, setPlayer1Wins] = useState(0);
  const [player2Wins, setPlayer2Wins] = useState(0);
  const [playSpeed, setPlaySpeed] = useState(1);
  const [currentPlayer1Score, setCurrentPlayer1Score] = useState(0);
  const [currentPlayer2Score, setCurrentPlayer2Score] = useState(0);
  const [isFinished, setIsFinished] = useState(false);

  const eventSet = useMemo(() => new Set(), []);

  const formatElapsedTime = useMemo(() => {
    let minutes = Math.floor(elpasedTime / 60);
    let seconds = elpasedTime % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  }, [elpasedTime]);

  const intervalIdRef = useRef(null);

  const handleRestart = () => {
    setMatchIndex(0);
    setCurrentEventIndex(0);
    setElapsedTime(0);
    setIsLegFinished(false);
    setDisplayEvents([]);
    setPlayer1Reached301(false);
    setPlayer2Reached301(false);
    setPlayer1Wins(0);
    setPlayer2Wins(0);
    setCurrentPlayer1Score(0);
    setCurrentPlayer2Score(0);
    setIsFinished(false);
    eventSet.clear();
  };

  const handlePlaySpeed = () => {
    setPlaySpeed((prev) => {
      if (prev === 1) return 2;
      else if (prev === 2) return 4;
      else return 1;
    });
  };

  useEffect(() => {
    const audio = new Audio(arenaXpSound);

    const updateElapsedTime = () => {
      setElapsedTime((prev) => prev + 1);
    };

    const playAudio = () => {
      audio.play().catch((err) => console.log("Audio--err-->>", err));
    };

    intervalIdRef.current = setInterval(() => {
      if (matchIndex < results.length) {
        setCurrentEventIndex((prev) => prev + 1);
        updateElapsedTime();
        if (isLegFinished) {
          setMatchIndex((prev) => prev + 1);
          setCurrentPlayer1Score(0);
          setCurrentPlayer2Score(0);
          setIsLegFinished(false);
          setCurrentEventIndex(0);
          setPlayer1Reached301(false);
          setPlayer2Reached301(false);
          setDisplayEvents([]);
          eventSet.clear();
        }
      } else {
        clearInterval(intervalIdRef.current);
        setIsFinished(true);
        if (!isReply) {
          if (user?.username === winner) {
            playAudio();
          }
          if (!toast.isActive("arena-notification") && message) {
            toast.info(message, { toastId: "arena-notification" });
          }
        }
      }
    }, 2000 / playSpeed);

    return () => {
      clearInterval(intervalIdRef.current);
    };
  }, [
    results,
    isReply,
    playSpeed,
    matchIndex,
    isLegFinished,
    eventSet,
    message,
    user,
    winner,
  ]);

  const stopIntervals = () => {
    clearInterval(intervalIdRef.current);
  };

  useEffect(() => {
    if (matchIndex >= results.length) return;

    let result = results[matchIndex];
    let turn = currentEventIndex % 2 === 0 ? 1 : 2;
    let player = turn === 1 ? player1 : player2;
    let index = Math.floor(currentEventIndex / 2);

    const addEvent = (event) => {
      if (!eventSet.has(event)) {
        setDisplayEvents((prev) => [...prev, event]);
        eventSet.add(event);
      }
    };

    if (turn === 1) {
      if (index < result.player1Scores.length) {
        setCurrentPlayer1Score(501 - result.player1Scores[index]);
      }

      if (index === 0) {
        addEvent(`Leg ${matchIndex + 1}: ${player} starts throwing`);
      }

      if (
        !isLegFinished &&
        result.winner === player1 &&
        index === result.player1Scores.length - 1
      ) {
        setCurrentPlayer1Score(501);
        addEvent(`Leg ${matchIndex + 1}: ${player} checks out`);
        addEvent(`Leg ${matchIndex + 1}: ${player} wins`);
        setPlayer1Wins((prev) => prev + 1);
        setIsLegFinished(true);
      }

      if (!player1Reached301 && result.player1Scores[index] <= 301) {
        addEvent(`Leg ${matchIndex + 1}: ${player} reaches 301`);
        setPlayer1Reached301(true);
      }
    } else if (turn === 2) {
      if (index < result.player2Scores.length) {
        setCurrentPlayer2Score(501 - result.player2Scores[index]);
      }

      if (index === 0) {
        addEvent(`Leg ${matchIndex + 1}: ${player} starts throwing`);
      }

      if (
        !isLegFinished &&
        result.winner === player2 &&
        index === result.player2Scores.length - 1
      ) {
        setCurrentPlayer2Score(501);
        addEvent(`Leg ${matchIndex + 1}: ${player} checks out`);
        addEvent(`Leg ${matchIndex + 1}: ${player} wins`);
        setPlayer2Wins((prev) => prev + 1);
        setIsLegFinished(true);
      }

      if (!player2Reached301 && result.player2Scores[index] <= 301) {
        addEvent(`Leg ${matchIndex + 1}: ${player} reaches 301`);
        setPlayer2Reached301(true);
      }
    }
  }, [
    results,
    player1,
    player2,
    matchIndex,
    currentEventIndex,
    isLegFinished,
    player1Reached301,
    player2Reached301,
    eventSet,
  ]);

  return {
    displayEvents,
    matchIndex,
    formatElapsedTime,
    player1Wins,
    player2Wins,
    playSpeed,
    currentPlayer1Score,
    currentPlayer2Score,
    isFinished,
    handleRestart,
    handlePlaySpeed,
    stopIntervals,
  };
};

export default useMatchEvents;
