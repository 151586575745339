import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

const AuthGuard = () => {
  const { auth } = useAuth();
  const { user, token } = auth;
  return user && token ? <Outlet /> : <Navigate to={"/login"} replace />;
};

export default AuthGuard;
