import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

import { fetchResult } from "../app/resultSlice";
import HandleAchievement from "../helper/achievements";
import AchievementImages from "../helper/images";

export const useProfile = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const auth = useSelector((state) => state.auth);
  const resultState = useSelector((state) => state.result);
  const { user } = auth;
  const { result, isLoading } = resultState;

  useEffect(() => {
    dispatch(fetchResult(user?.username));
  }, [dispatch, user?.username, user?._id]);

  const pyramidAchievementItems = useMemo(() => {
    return [
      {
        result: result?.pyramidClimber,
        achievement: t("climbThePyramid"),
        title: t("pyramidClimber"),
        type: "both",
        max: 20,
        achievementIcons: AchievementImages.PYRAMIDCLIMBER,
        handleActive: HandleAchievement.pyramidClimber,
      },
      {
        result: {
          lifetime: result?.maxVictoryStreak,
          season: result?.seasonMaxVictoryStreak,
        },
        title: t("victoryStreak"),
        achievement: t("victoryStreak"),
        type: "both",
        max: 10,
        achievementIcons: AchievementImages.MAXVICTORYSTREAK,
        handleActive: HandleAchievement.streakActive,
      },

      {
        result: result?.challengeConqueror,
        achievement: t("acceptAChallengeAndWinTheMatch"),
        title: t("challengeConqueror"),
        type: "both",
        max: 50,
        achievementIcons: AchievementImages.CHALLENGECONQUEROR,
        handleActive: HandleAchievement.pyramidClimber,
      },
      {
        result: result?.monthlyMaestro,
        achievement: t("finishInTheTop4Positions"),
        title: t("monthlyMaestro"),
        type: "both",
        max: 9,
        achievementIcons: AchievementImages.MONTHLYMAESTRO,
        handleActive: HandleAchievement.monthlyMaestro,
      },
      {
        result: result?.pyramidProtector,
        achievement: t("defendThePosition"),
        title: t("pyramidProtector"),
        type: "both",
        max: 20,
        achievementIcons: AchievementImages.PYRAMIDPROTECTOR,
        handleActive: HandleAchievement.pyramidProtector,
      },
    ];
  }, [result, t]);

  const fightAchievementItems = useMemo(() => {
    return [
      {
        result: result?.friendlyChallenger,
        achievement: t("friendlyChallenger"),
        title: t("friendlyChallenger"),
        type: "both",
        max: 100,
        achievementIcons: AchievementImages.FRIENDLYCHALLENGER,
        handleActive: HandleAchievement.friendlyActive,
      },
      {
        result: result?.readyForIt,
        achievement: t("acceptACertainNumberOfChallenges"),
        title: t("readyForIt"),
        type: "both",
        max: 50,
        achievementIcons: AchievementImages.READYFORIT,
        handleActive: HandleAchievement.readyForIt,
      },
      {
        result: result?.dartEnthusiast,
        achievement: t("highNumberOfMatchesOrChallenges"),
        title: t("dartEnthusiast"),
        type: "both",
        max: 9,
        achievementIcons: AchievementImages.DARTENTHUSIAST,
        handleActive: HandleAchievement.dartEnthusiast,
      },
    ];
  }, [result, t]);

  const personalAchievementItems = useMemo(() => {
    return [
      {
        result: result?.master180,
        achievement: t("maximumScoreOf180"),
        title: t("180Master"),
        type: "both",
        max: 100,
        achievementIcons: AchievementImages.MASTER180,
        handleActive: HandleAchievement.master180,
      },
      {
        result: result?.consistentScorer,
        achievement: t("scoreConsistentlyOver100"),
        title: t("consistentScorer"),
        type: "both",
        max: 50,
        achievementIcons: AchievementImages.CONSISTENTSCORER,
        handleActive: HandleAchievement.consistentScorer,
      },
      {
        result: result?.master26,
        achievement: t("breakfast"),
        title: t("breakfast"),
        type: "both",
        max: 1000,
        achievementIcons: AchievementImages.MASTER26,
        handleActive: HandleAchievement.breakfastActive,
      },
      {
        result: result?.ironDart,
        achievement: t("highestCheckoutPercentage"),
        title: t("ironDart"),
        type: "both",
        max: 100,
        achievementIcons: AchievementImages.IRONDART,
        handleActive: HandleAchievement.ironDart,
      },
    ];
  }, [result, t]);

  const highIndex = useMemo(
    () =>
      result?.highFinish?.reduce((acc, element, index) => {
        if (Number(element) !== 0) {
          acc.push(index);
        }
        return acc;
      }, []),
    [result]
  );

  return {
    pyramidAchievementItems,
    fightAchievementItems,
    personalAchievementItems,
    highIndex,
    result,
    isLoading,
  };
};
