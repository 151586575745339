import { motion } from "framer-motion";

import { useProfile } from "../../hooks/useProfile";
import Loading from "../../components/Loading";
import AchievementImages from "../../helper/images";
import AchievementItemComponent from "../../components/Profile/AchievementItem";
import AchievementChampionChallenger from "../../components/Profile/AchievementChampionChallenger";
import constant from "../../helper/constant";

const ProfilePyramidAchievements = () => {
  const { fightAchievementItems, result, isLoading } = useProfile();
  return (
    <div>
      {isLoading ? (
        <div className="flex flex-col w-full p-4 bg-white dark:bg-gray-800 text-black dark:text-white gap-4">
          <Loading />
          <Loading />
          <Loading />
          <Loading />
        </div>
      ) : (
        <div className="p-4 shadow-md rounded-md">
          <motion.ul
            variants={constant.Animationcontainer}
            initial="hidden"
            animate="visible"
            className="flex flex-col lg:flex-row sm:flex-wrap gap-4"
          >
            <AchievementChampionChallenger
                result={result?.championChallenger}
                achievementIcon={AchievementImages.CHAMPIONCHALLENGER}
              />
            {fightAchievementItems.map((item, index) => (
              <AchievementItemComponent
                key={index}
                result={item.result}
                title={item.title}
                achievement={item.achievement}
                type={item.type}
                max={item.max}
                iconSize="w-16 h-12"
                achievementIcons={item.achievementIcons}
                handleActive={item.handleActive}
              />
            ))}
          </motion.ul>
        </div>
      )}
    </div>
  );
};

export default ProfilePyramidAchievements;
