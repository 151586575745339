import { Disclosure } from "@headlessui/react";
import { Link } from "react-router-dom";

import HeaderProfileDropdown from "./Header/HeaderProfileDropdown";
import DarkModeSwitcher from "./DarkModeSwitcher";
import Bell from "./Header/Bell";
import MobileMenuButton from "./Header/MobileMenuButton";
import LanguageSelect from "./Header/LanguageSelect";
import { useAuth } from "../hooks/useAuth";
import { useSocketEvent } from "../hooks/useSocketEvent";
import images from "../helper/images";

const Header = ({ isOpenSidebar = false, onOpenSidebar, onCloseSidebar }) => {
  const { logout, auth } = useAuth();
  const { user } = auth;
  const { notifications, handleAllNotificationsRead, handleNotificationClick } =
    useSocketEvent();
  const { LOGO } = images;
  return (
    <div className="max-w-10xl mx-auto dark:bg-gray-800 py-6 bg-white h-24 fixed w-full top-0 z-50">
      <Disclosure as="nav" className="flex items-center px-8">
        {({ open }) => (
          <>
            <div className="-mr-2 flex md:hidden">
              {/* Mobile menu button */}
              <MobileMenuButton
                isOpenSidebar={isOpenSidebar}
                onOpenSidebar={onOpenSidebar}
                onCloseSidebar={onCloseSidebar}
              />
            </div>
            <Link to="/" className="hidden md:block ml-12">
              <img className="w-24" src={LOGO} alt="logo-img" />
            </Link>

            <div className="flex items-center justify-between ml-auto">
              <div className="ml-auto lg:ml-6 mr-0 sm:mr-4">
                <div className="flex items-center gap-1 sm:gap-5">
                  <DarkModeSwitcher />
                  <Bell
                    notifications={notifications}
                    handleAllNotificationsRead={handleAllNotificationsRead}
                    handleNotificationClick={handleNotificationClick}
                  />
                  <LanguageSelect />
                  <HeaderProfileDropdown
                    userAvatar={user?.avatar}
                    userName={user?.username}
                    handleLogout={logout}
                  />
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default Header;
