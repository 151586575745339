const TranslationIcon = () => {
  return (
    <svg
      width="44"
      height="39"
      viewBox="0 0 44 39"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_13_817)">
        <path
          d="M33.2502 35.1758C30.5301 37.5962 27.395 38.8755 24.3096 38.9913C21.1018 39.1117 17.954 37.9751 15.3849 35.5576L14.0831 34.3326L9.43052 30.4958H5.2995C3.11617 30.4958 1.54979 28.6733 1.32798 26.473L0.0167497 13.4677C-0.205055 11.2671 1.81104 9.26678 3.98827 9.44462L20.2542 10.7729C22.4314 10.9506 24.2257 12.5829 24.2257 14.7959V26.473C24.2257 28.6856 22.4378 30.4958 20.2542 30.4958H15.1561L15.5188 34.6333L15.8968 34.8507C18.7522 36.4927 21.552 37.1948 24.2088 36.8397C26.6372 36.515 28.9626 35.3024 31.1192 33.1115L29.7915 31.9273L35.3154 30.6817L34.6681 36.5409L33.2502 35.1758Z"
          fill="#424C57"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.7458 6.43397L33.4669 5.64015L30.8736 4.14923C28.0181 2.50753 25.2182 1.8052 22.5617 2.16028C20.1331 2.48498 17.8077 3.6976 15.6511 5.88842L16.9788 7.07267L11.455 8.31828L12.1023 2.45913L13.5201 3.82426C16.2404 1.40398 19.3755 0.1245 22.4609 0.00863457C25.6687 -0.11167 28.8165 1.02478 31.3856 3.44246L33.7009 5.62109L40.0116 5.10575C42.1889 4.92799 44.205 6.92825 43.9832 9.12891L42.672 22.1341C42.4502 24.3345 40.8838 26.1569 38.7004 26.1569H34.395L28.2234 31.2465L28.6694 26.1569H23.7458C21.5621 26.1569 19.7741 24.3469 19.7741 22.1341V10.4571C19.7741 8.24412 21.5686 6.61182 23.7458 6.43397Z"
          fill="#337EC6"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.2399 15.122H11.0075V14.0467H12.6906V15.122H16.4719V16.8875H15.6956C15.5556 18.0027 15.2479 18.9217 14.7248 19.9122C14.2823 20.7488 13.7111 21.5548 13.0476 22.3273C14.1064 23.61 15.4209 24.726 16.9858 25.7432L16.1202 27.11C14.4771 26.0422 13.0818 24.8664 11.9351 23.512C10.9093 24.5172 9.74403 25.4535 8.52337 26.3087L7.61347 24.9747C8.82132 24.1281 9.96876 23.2034 10.9561 22.2176C10.0462 20.8689 9.34932 19.3553 8.86712 17.6147L10.4112 17.1762C10.8066 18.6039 11.361 19.8606 12.0736 20.9854C12.5598 20.3893 12.9801 19.7775 13.3122 19.1494C13.7195 18.3776 13.9383 17.7395 14.0761 16.8875H7.2399V15.122ZM34.2423 18.7434H29.4357L28.8035 20.9275H26.4132C27.4435 18.1654 28.6331 14.8488 29.6638 12.0854C30.0349 11.0873 30.4574 9.43323 31.8074 9.43323C33.2068 9.43323 33.6714 10.9487 34.0608 11.9957L37.3608 20.9726H34.8937L34.2423 18.7434ZM33.7267 16.6195L31.8453 11.5964L29.9571 16.6195H33.7267Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_13_817">
          <rect width="44" height="39" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default TranslationIcon;
