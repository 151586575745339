import { useEffect, useState } from "react";
import { motion } from "framer-motion";

import images from "../../helper/images";
import constant from "../../helper/constant";
import { getRandomInt } from "../../helper/helper";

const HomeQuote = () => {
  const [quote, setQuote] = useState(constant.quotes[0]);
  const { HOMEQUOTE1, HOMEQUOTE2 } = images;

  useEffect(() => {
    setQuote(constant.quotes[getRandomInt(0, constant.quotes.length)]);
  }, []);
  return (
    <motion.ul
      variants={constant.Animationcontainer}
      initial="hidden"
      animate="visible"
      className="flex flex-col gap-4"
    >
      <motion.li variants={constant.TopDownAnimationitem}>
        <div className="relative">
          <div className="flex">
            <img className="w-full h-full" src={HOMEQUOTE1} alt="board" />
          </div>
          <p className="absolute w-full p-4 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white font-semibold text-md sm:text-2xl">
            {quote.origin}
          </p>
        </div>
      </motion.li>
      <motion.li variants={constant.TopDownAnimationitem}>
        <div className="relative">
          <div className="flex">
            <img className="w-full h-full" src={HOMEQUOTE2} alt="board" />
          </div>
          <p className="absolute w-full p-4 top-1/2 left-1/2 -translate-y-1/2 -translate-x-1/2 text-white font-semibold text-md sm:text-2xl">
            {quote.additive}
          </p>
        </div>
      </motion.li>
    </motion.ul>
  );
};

export default HomeQuote;
